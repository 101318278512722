"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _editDeclare = require("@/api/editDeclare.js");

var _moment = _interopRequireDefault(require("moment"));

var _enum = require("@/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'declareList',

  data() {
    return {
      pageTitle: '申报记录',
      //页面标题
      listData: [],
      page: 1,
      //当钱页数
      loading: false,
      //是否处于加载状态
      finished: false,
      //是否已加载完成
      refreshing: false,
      finishedText: '轻触查看三个月前的记录',
      //加载完成后的提示文案
      isHistory: 0,
      //是否查询三月前记录，0:查询最新记录；1:查询三月前记录
      isLogin: false,
      //是否已登录
      pageSize: 10,
      //每页记录条数
      totalElements: 1,
      //总申报记录条数
      typesOfPollutantsAllOptions: Object.keys(_enum.SC_WASTE_TYPE).map(id => ({
        id,
        text: _enum.SC_WASTE_TYPE[id],
        value: '0.00'
      })),
      statusListInfo: [{
        label: '待接单',
        value: 1
      }, //对应待分配
      {
        label: '待接单',
        value: 7
      }, {
        label: '进行中',
        value: 2
      }, //对应待接收
      {
        label: '进行中',
        value: 6
      }, {
        label: '已完成',
        value: 5
      }, //待转移
      {
        label: '进行中',
        value: 8
      }, //进行中，只转移了一部分
      {
        label: '已完成',
        value: 3
      }, {
        label: '已取消',
        value: 4
      }, {
        label: '已拒绝',
        value: 10
      }]
    };
  },

  created() {
    this.isLogin = localStorage.getItem('token');
  },

  components: {
    navBar: _navBar.default
  },
  computed: {// hasBg(){
    //   return document.getElementById("list_main").offsetHeight < document.body.clientHeight
    // }
  },

  mounted() {
    if (this.isLogin) {
      this.fetchApplicationsList(1, 0);
    }
  },

  methods: {
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.listData = [];
      this.page = 1; // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.isHistory = 0;
      this.refreshing = false;
      this.fetchApplicationsList(1, 0);
    },

    //下拉加载
    onLoad() {
      if (this.page >= Math.ceil(this.totalElements / this.pageSize)) {
        //最后一页
        this.finished = true;

        if (this.isHistory) {
          this.finishedText = '没有更多了';
        } else {
          this.finishedText = '轻触查看三个月前的记录';
        }
      } else {
        this.finished = false;
        this.page++;
        this.fetchApplicationsList(this.page, this.isHistory);
      }
    },

    //请求数据
    async fetchApplicationsList(page, history) {
      const res = await (0, _editDeclare.getApplicationsListApi)({
        page,
        size: 10,
        history
      });

      if (res && res.code === 200) {
        this.pageSize = res.data.pageSize;
        this.totalElements = res.data.totalElements;
        this.listData = this.listData.concat(res.data.content || []);
        this.listData = this.listData.map(this.formatListData); // console.log("listData: ", this.listData)

        if (this.page >= Math.ceil(this.totalElements / this.pageSize)) {
          //最后一页
          this.finished = true;

          if (this.isHistory) {
            this.finishedText = '没有更多了';
          } else {
            this.finishedText = '轻触查看三个月前的记录';
          }
        } else {
          this.finished = false;
        }
      }

      this.loading = false;
    },

    //查看三个月前的记录
    handleHistory() {
      if (!this.isHistory) {
        this.isHistory = 1;
        this.page = 1;
        this.listData = [];
        this.fetchApplicationsList(this.page, 1);
      }
    },

    // 处理请求来的数据
    formatListData(data) {
      let statusTitle = ((this.statusListInfo.filter(e => {
        return e.value === data.status;
      }) || [])[0] || {}).label;
      return { ...data,
        wasteInfos: Array.isArray(data.wasteInfos) ? data.wasteInfos.map(item => {
          return {
            id: Number(item.id),
            value: item.value,
            text: _enum.SC_WASTE_TYPE[item.id]
          };
        }) : Object.keys(data.wasteInfos).map(item => {
          return {
            id: item,
            value: data.wasteInfos[item],
            text: _enum.SC_WASTE_TYPE[item]
          };
        }),
        statusTitle: statusTitle,
        workTimeStr: (0, _moment.default)(data.workTime).format('YYYY-MM-DD HH:mm') + '-' + (0, _moment.default)(data.workTime + 60 * 60 * 1000).format('HH:mm')
      };
    },

    //跳转详情
    toDetail(item) {
      this.$router.push({
        path: '/declare_detail',
        query: {
          id: item.id
        }
      });
    },

    //查看联单
    seeOrder(pdfs) {
      this.$router.push({
        name: 'orderPdfList',
        query: {
          pdfs: JSON.stringify(pdfs)
        }
      });
    },

    //跳转登录
    toLogin() {
      this.$router.push('/login');
    }

  }
};
exports.default = _default;