// 正式
module.exports = {
  title: 'vue-h5-template',
  baseUrl: 'https://betamerapi.tian-wang.com/',
  // 正式项目地址
  baseApi: 'https://betamerapi.tian-wang.com/',
  // 正式api请求地址
  APPID: 'xxx',
  APPSECRET: 'xxx',
  $cdn: 'https://imgs.solui.cn'
};