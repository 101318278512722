"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _SendCode = _interopRequireDefault(require("components/user/SendCode"));

var _user = require("api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      pageTitle: "修改手机号",
      step: 2,
      // 1 输入原手机号 2 发送验证码 3  修改新手机号 4 修改成功
      oldIphone: '',
      // 原手机号
      newIphone: '',
      // 新手机号
      code: '',
      // 验证码
      newCode: '',
      // 新手机的验证码
      uuid: '',
      // 给新手机发送验证码使用
      mobile: '' // 当前手机绑定手机号

    };
  },

  components: {
    navBar: _navBar.default,
    SendCode: _SendCode.default
  },

  mounted() {
    this.mobile = localStorage.getItem('mobile');
  },

  methods: {
    successFun() {
      console.log('成功');
    },

    validatorIphone(val) {
      // 验证输入手机号
      return this.$tool.validatorIphone(val);
    },

    next() {
      let {
        step,
        code,
        newIphone,
        oldIphone,
        uuid,
        newCode
      } = this;

      if (step == 2) {
        if (!code) return (0, _toast.default)('请输入验证码');
        (0, _user.accountsSmsValidation)({
          smsCode: code
        }).then(res => {
          if (res.code == 200) {
            this.step += 1;
            this.uuid = res.data;
            this.$refs.scode.clearTimer();
          } else {
            (0, _toast.default)(res.msg);
          }
        });
      }

      if (step === 3 && !this.newIphone) return this.$toast('请输入新手机号'); // 校验新手机号是否输入正确

      if (step === 3 && !this.validatorIphone(this.newIphone)) return this.$toast('请输入正确手机号');

      if (step === 3) {
        (0, _user.editMobile)({
          mobile: newIphone,
          smsCode: newCode,
          uuid: this.uuid
        }).then(res => {
          if (res.code == 200) {
            (0, _toast.default)('手机号修改成功');
            this.step += 1;
          } else {
            (0, _toast.default)(res.msg);
          }
        });
      } // 最后一步， 返回账户中心即可


      if (step === 4) return this.$router.replace('/accountCenter');
    }

  },
  computed: {
    btnText() {
      let val = '下一步';

      switch (this.step) {
        case 3:
          val = '提交';
          break;

        case 4:
          val = '返回账户中心';
          break;
      }

      return val;
    }

  }
};
exports.default = _default;