"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _axios = _interopRequireDefault(require("axios"));

var _store = _interopRequireDefault(require("@/store"));

var _vue = _interopRequireDefault(require("vue"));

var _qs = _interopRequireDefault(require("qs"));

var _config = require("@/config");

// 根据环境不同引入不同api地址
// create an axios instance
const service = _axios.default.create({
  baseURL: _config.baseApi,
  // url = base api url + request url
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 20000 // request timeout

}); // request拦截器 request interceptor


service.interceptors.request.use(config => {
  // 不传递默认开启loading
  if (!config.hideloading) {
    // loading
    _toast.default.loading({
      forbidClick: true
    });
  }

  config.headers['Content-Type'] = 'application/json';

  if (localStorage.getItem('token')) {
    config.headers['token'] = localStorage.getItem('token');
  }

  return config;
}, error => {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // respone拦截器

service.interceptors.response.use(response => {
  _toast.default.clear();

  const res = response.data;

  if (res.code == 900001) {
    // token验证失败，重新登录
    (0, _toast.default)(res.msg);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('shipName');
    location.href = '#/login?url=' + location.hash.slice(1);
  }

  if (res.status && res.status !== 200) {
    // 登录超时,重新登录
    if (res.status === 401) {
      _store.default.dispatch('FedLogOut').then(() => {
        location.reload();
      });
    }

    return Promise.reject(res || 'error');
  } else {
    return Promise.resolve(res);
  }
}, error => {
  _toast.default.clear();

  console.log('err' + error); // for debug

  return Promise.reject(error);
});
var _default = service;
exports.default = _default;