"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _menuNavigation = _interopRequireDefault(require("components/menuNavigation.vue"));

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'App',
  components: {
    menuNavigation: _menuNavigation.default
  }
};
exports.default = _default;