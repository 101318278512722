"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _user = require("api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      pageTitle: '修改密码',
      step: 1,
      // 1 输入原密码 2 输入新密码 3 修改成功
      oldPass: '',
      // 旧密码
      newPass: '',
      // 新密码
      confirmPass: '',
      // 确认新密码
      uuid: '' //  确认旧密码后返回值

    };
  },

  components: {
    navBar: _navBar.default
  },
  computed: {
    btnText() {
      let val = '下一步';

      switch (this.step) {
        case 2:
          val = '提交';
          break;

        case 3:
          val = '返回账户中心';
          break;
      }

      return val;
    }

  },
  methods: {
    next() {
      let {
        step,
        oldPass,
        newPass,
        confirmPass,
        uuid
      } = this;

      if (step === 3) {
        // 最后一步， 返回账户中心即可
        this.$router.replace('/accountCenter');
        return;
      }

      if (step === 1) {
        if (oldPass.trim() === '') return (0, _toast.default)('请输入原密码');
        (0, _user.accountPwdValidation)({
          password: oldPass
        }).then(res => {
          if (res.code == 200) {
            this.uuid = res.data;
            this.step += 1;
          } else {
            (0, _toast.default)(res.msg);
          }
        });
      }

      if (step === 2) {
        // 密码校验 ， 是否符合规则 ， 二次输入是否和一次相同
        if (newPass.trim() == '') return (0, _toast.default)('请填写密码');
        if (confirmPass.trim() == '') return (0, _toast.default)('请填写确认密码');
        if (confirmPass !== newPass) return (0, _toast.default)('两次填写密码不一致');
        (0, _user.accountPwd)({
          newPassword: newPass,
          newPasswordRepeat: confirmPass,
          uuid
        }).then(res => {
          if (res.code == 200) {
            (0, _toast.default)('修改成功');
            this.step += 1;
          } else {
            (0, _toast.default)(res.msg);
          }
        });
      }
    }

  }
};
exports.default = _default;