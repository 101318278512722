"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _user = require("@/api/user");

var _common = require("@/api/common");

var _default = {
  components: {
    navBar: _navBar.default
  },

  data() {
    return {
      show: false,
      pageTitle: '注册新账号',
      form: {
        shipName: '',
        shipNo: '',
        linkman: '',
        password: '',
        company: ''
      },
      license: [// { url: 'https://img.yzcdn.cn/vant/leaf.jpg' }
        // Uploader 根据文件后缀来判断是否为图片文件
      ]
    };
  },

  computed: {},
  methods: {
    onSubmit() {
      // uuid 上级页面提交后返回的 uuid
      this.form.license = this.license[0] && this.license[0].url;
      this.form.uuid = this.$route.query.data;
      this.form.linkman = this.form.linkman || null; // 绕过后端字符串非空校验的bug

      this.form.company = this.form.company || null; // 绕过后端字符串非空校验的bug

      (0, _user.register)(this.form).then(res => {
        if (res.code === 200) {
          // 注册成功
          this.show = true;
        } else if (res.code == 500007) {
          this.$toast(res.msg);
          this.$router.go(-1);
        } else this.$toast(res.msg);
      });
    },

    iKonw() {
      // 注册成功 跳转登录页面登录
      this.show = false;
      this.$router.replace('/login');
    },

    afterRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';
      let formdata = new FormData();
      formdata.append('file', file.file);
      (0, _common.upload)(formdata).then(res => {
        if (res.code == 200) {
          // 上传成功 保存到图片数组
          file.status = 'done';
          file.message = '上传完成';
          this.license[0].url = res.data;
        }
      }).catch(err => {
        if (err.message.indexOf('timeout') > -1) {
          _toast.default.fail('请求超时');
        }

        file.status = 'failed';
        file.message = '上传失败';
      });
    },

    // 限制文件大小
    onOversize(file) {
      _toast.default.fail('文件太大');
    },

    // 上传前的校验
    // beforeRead(file) {
    //   // if (file.type !== 'image/jpeg') {
    //   //   Toast('请上传 jpg 格式图片');
    //   //   return false;
    //   // }
    //   return true;
    // },
    // 上传前返回的Promise
    asyncBeforeRead(file) {
      return new Promise((resolve, reject) => {
        if (file.type !== 'image/jpeg') {
          (0, _toast.default)('请上传 jpg 格式图片');
          reject();
        } else {
          let img = new File(['foo'], 'bar.jpg', {
            type: 'image/jpeg'
          });
          resolve(img);
        }
      });
    },

    nameValidator(val) {
      // if(!val) return this.$toast('123131')
      return val.length >= 3;
    },

    shipNoValidator(val) {
      return val.length >= 5;
    },

    linkmanValidator(val) {
      if (!val) return true;
      return val.length >= 1;
    },

    passwordValidator(val) {
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
      return reg.test(val);
    },

    companyValidator(val) {
      if (!val) return true;
      return val.length >= 3;
    }

  }
};
exports.default = _default;