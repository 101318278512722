"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.loginSimple = loginSimple;
exports.loginSmsCode = loginSmsCode;
exports.loginAndRegisterCode = loginAndRegisterCode;
exports.loginAndRegister = loginAndRegister;
exports.validation = validation;
exports.register = register;
exports.accountPwd = accountPwd;
exports.accountPwdValidation = accountPwdValidation;
exports.accountsSms = accountsSms;
exports.accountsSmsValidation = accountsSmsValidation;
exports.newMobileCode = newMobileCode;
exports.editMobile = editMobile;
exports.smsPwd = smsPwd;
exports.smsPwdValidation = smsPwdValidation;
exports.pwdForget = pwdForget;

var _qs = _interopRequireDefault(require("qs"));

var _request = _interopRequireDefault(require("@/utils/request"));

// axios
//user api
// ---------登录 -------
// 登录
function login(params) {
  return (0, _request.default)({
    url: '/login',
    method: 'post',
    data: { ...params
    }
  });
} // 手机验证码登录


function loginSimple(params) {
  return (0, _request.default)({
    url: '/login/simple',
    method: 'post',
    data: { ...params
    }
  });
} // 登录发送验证码


function loginSmsCode(mobile, params) {
  return (0, _request.default)({
    url: '/sms/login/' + mobile,
    method: 'get' // params: {
    //   ...params
    // }

  });
} // 手机发送验证码 - 登陆注册一起


function loginAndRegisterCode(mobile) {
  return (0, _request.default)({
    url: '/sms/loginAndRegister/' + mobile,
    method: 'get'
  });
} // 登陆注册


function loginAndRegister(params) {
  return (0, _request.default)({
    url: '/loginAndRegister/simple',
    method: 'post',
    data: params
  });
} // ----- 注册 ------
// 注册 验证手机号


function validation(mobile, params) {
  return (0, _request.default)({
    url: '/sms/validation/' + mobile,
    method: 'get',
    params: { ...params
    }
  });
} // 注册 提交用户信息


function register(params) {
  return (0, _request.default)({
    url: '/register',
    method: 'post',
    data: { ...params
    }
  });
} // ------- 修改密码 -----
// 修改密码提交 


function accountPwd(params) {
  return (0, _request.default)({
    url: '/accounts/pwd',
    method: 'put',
    data: { ...params
    }
  });
} // 校验旧密码


function accountPwdValidation(params) {
  return (0, _request.default)({
    url: '/accounts/pwd/validation',
    method: 'post',
    data: { ...params
    }
  });
} // --------- 修改手机号 --------
// 发送验证码到当前手机 /accounts/sms get 


function accountsSms(params) {
  return (0, _request.default)({
    url: '/accounts/sms',
    method: 'get',
    params: { ...params
    }
  });
} // 校验当前手机验证码 /accounts/sms/validation get 


function accountsSmsValidation(params) {
  return (0, _request.default)({
    url: '/accounts/sms/validation',
    method: 'get',
    params: { ...params
    }
  });
} // 发送验证码到新手机 /accounts/sms/:mobile get 


function newMobileCode(mobile, params) {
  return (0, _request.default)({
    url: '/accounts/sms/' + mobile,
    method: 'get',
    params: { ...params
    }
  });
} // 修改手机号码提交 /accounts/sms put 


function editMobile(params) {
  return (0, _request.default)({
    url: '/accounts/sms',
    method: 'put',
    data: { ...params
    }
  });
} // 忘记密码 --------
//  请求验证码


function smsPwd(mobile, params) {
  return (0, _request.default)({
    url: '/sms/pwd/' + mobile,
    method: 'post',
    data: { ...params
    }
  });
} // 校验短信验证码


function smsPwdValidation(mobile, params) {
  return (0, _request.default)({
    url: '/sms/pwd/validation/' + mobile,
    method: 'get',
    params: { ...params
    }
  });
} // 忘记密码提交


function pwdForget(params) {
  return (0, _request.default)({
    url: '/pwd/forget',
    method: 'post',
    data: { ...params
    }
  });
}