var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("drag-verify", {
    ref: "Verify",
    attrs: {
      width: _vm.nWidth,
      height: _vm.height,
      text: _vm.text,
      color: _vm.color,
      "success-text": _vm.successText,
      background: _vm.background,
      "progress-bar-bg": _vm.progressBarBg,
      "completed-bg": _vm.completedBg,
      "handler-bg": _vm.handlerBg,
      "handler-icon": _vm.handlerIcon,
      "text-size": _vm.textSize,
      "success-icon": _vm.successIcon,
      circle: false
    },
    on: { passcallback: _vm.passcallback }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }