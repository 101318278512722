"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slider = _interopRequireDefault(require("@/components/slider"));

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _utils = require("@/utils");

var _common = require("@/api/common");

var _user = require("@/api/user");

var _timers = require("timers");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    slider: _slider.default,
    navBar: _navBar.default
  },

  data() {
    return {
      pageTitle: '注册新账号',
      show: false,
      phone: '',
      code: '',
      sliderRes: {},
      isSend: false,
      countDowm: 60
    };
  },

  mounted() {
    this.slider();
  },

  // http://localhost:8090/sms/robot/:mobile
  // http://devmerapi.tian-wang.com/sms/robot/:18330102802
  methods: {
    // 发送验证码
    send() {
      if (!this.phone) return this.$toast('请输入手机号');
      if (!this.sliderRes.sig) return this.$toast('请完成滑动验证');
      (0, _common.smsCode)(this.phone, this.sliderRes).then(res => {
        this.$toast('验证码已发送');
        if (res.code !== 200) return this.$toast(res.msg);
        this.isSend = true;
        let timer = setInterval(() => {
          if (this.countDowm == 0) {
            this.isSend = false;
            this.countDowm = 60;
            this.nc.reset();
            this.sliderRes = {};
            return clearInterval(timer);
          }

          this.countDowm--;
        }, 1000);
      });
    },

    // 下一步
    sendNext() {
      //
      if (!this.phone) return this.$toast('请输入手机号');
      if (!this.sliderRes.sig) return this.$toast('请完成滑动验证');
      if (!this.code) return this.$toast('请输入验证码');
      (0, _user.validation)(this.phone, {
        mobile: this.phone,
        smsCode: this.code
      }).then(res => {
        if (res.code == 200) {
          this.$router.push('/registryCenter?data=' + res.data);
        } else this.$toast(res.msg);
      });
    },

    successFun() {
      //
      console.log(11111111111111111111111, '成功');
    },

    // 滑块js
    slider() {
      // 验证通过成功回调
      this.nc = (0, _utils.aliSlider)('nc_register', (data, nc_token) => {
        if (data.code == 0) {
          this.sliderRes = {
            "sig": data.sig,
            "sessionId": data.csessionid,
            "token": nc_token,
            "scene": data.scene
          };
        }
      });
    }

  }
};
exports.default = _default;