"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _pdfh = _interopRequireDefault(require("pdfh5"));

//
//
//
//
//
//
var _default = {
  name: 'orderDetail',

  data() {
    // 去除URL协议部分，使其与当前页面保持一致以，免浏览器安全限制导致无法加载
    let pdfSrc = this.$route.query.pdfSrc.replace(/^https?:/i, '');
    return {
      pageTitle: '联单概览',
      //页面title
      pdfSrc,
      pdfh5: null
    };
  },

  components: {
    navBar: _navBar.default
  },

  mounted() {
    //实例化
    this.pdfh5 = new _pdfh.default("#demo", {
      pdfurl: this.pdfSrc,
      lazy: true
    });
  }

};
exports.default = _default;