"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _compositionApi = require("@vue/composition-api");

var _default = fields => key => {
  let errors = (0, _compositionApi.unref)(fields)[key];

  if (errors) {
    return errors[0].message;
  }
};

exports.default = _default;