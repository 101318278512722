"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const state = {
  menuStatus: false
};
const mutations = {
  setMenuStatus(state, status) {
    state.menuStatus = status;
  }

};
const actions = {};
var _default = {
  state,
  mutations,
  actions
};
exports.default = _default;