var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isSend
        ? _c(
            "van-button",
            {
              staticStyle: { padding: "5px 10px", "align-items": "center" },
              attrs: {
                "native-type": "button",
                size: "mini",
                round: "",
                type: "default"
              },
              on: { click: _vm.sendCode }
            },
            [_vm._v(" 发送验证码 ")]
          )
        : _vm._e(),
      _vm.isSend
        ? _c(
            "van-button",
            {
              staticStyle: { padding: "5px 10px", "align-items": "center" },
              attrs: {
                disabled: "",
                "native-type": "button",
                size: "mini",
                round: "",
                type: "default"
              }
            },
            [_vm._v(" 重新发送(" + _vm._s(_vm.countDowm) + ")s ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }