"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _utils = require("@/utils");

var _user = require("api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      pageTitle: '找回密码',
      iphone: '',
      // 手机号
      step: 2,
      // 步骤 1 输入手机号  2 发送验证码 3 修改密码 4 修改成功
      code: '',
      // 短信验证码
      newPass: '',
      // 新密码
      confirmPass: '',
      // 再次确认
      isSend: false,
      // 是否发送验证码
      countDowm: 60,
      sliderRes: {},
      uuid: ''
    };
  },

  computed: {
    btnText() {
      let val = '下一步';

      switch (this.step) {
        case 3:
          val = '提交';
          break;

        case 4:
          val = '返回账户中心';
          break;
      }

      return val;
    }

  },
  components: {
    navBar: _navBar.default
  },

  mounted() {
    this.slider();
  },

  filters: {
    coverIpone(val) {
      return val.toString().slice(0, 3) + "****" + val.toString().slice(7);
    }

  },
  methods: {
    successFun() {
      console.log('success');
    },

    // 下方按钮
    next() {
      let {
        step,
        iphone,
        code,
        newPass,
        confirmPass,
        sliderRes
      } = this;

      if (step === 4) {
        // 最后一步， 返回账户中心即可
        this.$router.replace('/accountCenter');
        return;
      } // 每次点击验证传入的数据是否正确，再step+=1


      if (step === 1) {
        // 手机号符合规则并且通过人机校验
        this.sendCode().then(res => res ? this.step += 1 : null);
      }

      if (step === 2) {
        // 填写正确验证码
        if (code.trim() == '') return (0, _toast.default)('请填写验证码');
        (0, _user.smsPwdValidation)(iphone, {
          smsCode: code
        }).then(res => {
          if (res.code == 200) {
            this.uuid = res.data;
            this.step += 1;
          } else {
            (0, _toast.default)(res.msg);
          }
        });
      }

      if (step === 3) {
        // 密码校验 ， 是否符合规则 ， 二次输入是否和一次相同
        if (newPass.trim() == '') return (0, _toast.default)('请填写密码');
        if (confirmPass.trim() == '') return (0, _toast.default)('请填写确认密码');
        if (confirmPass !== newPass) return (0, _toast.default)('两次填写密码不一致');
        (0, _user.pwdForget)({
          newPassword: newPass,
          newPasswordRepeat: confirmPass,
          uuid: this.uuid
        }).then(res => {
          if (res.code == 200) {
            (0, _toast.default)('密码修改成功');
            this.step += 1;
          } else {
            (0, _toast.default)(res.msg);
          }
        });
      }
    },

    // 手机号验证
    validatorIphone(val) {
      return val.trim() == '' ? true : /^1\d{10}$/.test(val);
    },

    // 发送验证码
    sendCode() {
      let {
        iphone,
        code,
        sliderRes
      } = this;
      if (iphone.trim() == '') return (0, _toast.default)('请填写手机号');
      if (!/^1\d{10}$/.test(iphone)) return (0, _toast.default)('请输入正确的手机号');
      if (!sliderRes.token) return (0, _toast.default)('请滑动验证身份信息');
      return (0, _user.smsPwd)(iphone, { ...sliderRes
      }).then(res => {
        if (res.code == 200) {
          (0, _toast.default)('验证码已发送');
          this.openTimer();
          return true;
        } else {
          (0, _toast.default)(res.msg);
          return false;
        }
      });
    },

    // 计时器开启
    openTimer() {
      this.isSend = true;
      let timer = setInterval(() => {
        if (this.countDowm == 0) {
          this.isSend = false;
          this.countDowm = 60;
          this.nc.reset();
          this.sliderRes = {};
          return clearInterval(timer);
        }

        this.countDowm--;
      }, 1000);
    },

    // 滑块js
    slider() {
      // 验证通过成功回调
      this.nc = (0, _utils.aliSlider)('nc_retreve', (data, nc_token) => {
        if (data.code == 0) {
          this.sliderRes = {
            "sig": data.sig,
            "sessionId": data.csessionid,
            "token": nc_token,
            "scene": data.scene
          };
        }
      });
    }

  }
};
exports.default = _default;