var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "terr_select" },
    [
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", "close-on-click-overlay": false },
          on: { "click-overlay": _vm.cancelSelected },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _vm.treeSelectLiftList.length
            ? _c("van-tree-select", {
                attrs: {
                  height: "325px",
                  items: _vm.treeSelectLiftList,
                  "main-active-index": _vm.activeIndexData
                },
                on: {
                  "update:mainActiveIndex": function($event) {
                    _vm.activeIndexData = $event
                  },
                  "update:main-active-index": function($event) {
                    _vm.activeIndexData = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _vm.treeSelectRightList[_vm.activeIndexData][0] &&
                          _vm.treeSelectRightList[_vm.activeIndexData][0]
                            .shipName
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "ship_list_content" },
                                  _vm._l(
                                    _vm.treeSelectRightList[
                                      _vm.activeIndexData
                                    ],
                                    function(item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id + item.shipName,
                                          class: _vm.checkedItemsData.includes(
                                            item.id
                                          )
                                            ? "ship_list_item flex_style border_blue"
                                            : "ship_list_item flex_style border_white",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleSelectItem(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "ship_img_box" },
                                                [
                                                  _c("img", {
                                                    staticClass: "ship_img",
                                                    attrs: {
                                                      src: require("@/assets/ship@2x.png"),
                                                      alt: ""
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "ts_14 ship_title"
                                                },
                                                [
                                                  _c("li", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.shipName)
                                                      )
                                                    ])
                                                  ]),
                                                  _c("li", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "型号：" +
                                                          _vm._s(item.shipModel)
                                                      )
                                                    ])
                                                  ]),
                                                  _c("li", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "牌号：" +
                                                          _vm._s(item.shipNo)
                                                      )
                                                    ])
                                                  ]),
                                                  _c(
                                                    "li",
                                                    [
                                                      item.conflicting
                                                        ? _c(
                                                            "van-tag",
                                                            {
                                                              attrs: {
                                                                type: "danger"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "时间可能冲突"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticStyle: { width: "32px" } },
                                            [
                                              _vm.checkedItemsData.includes(
                                                item.id
                                              )
                                                ? _c("img", {
                                                    staticClass:
                                                      "ship_list_item_icon",
                                                    attrs: {
                                                      src: require("../../../../public/img/selected_icon.png"),
                                                      alt: ""
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              ])
                            : _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "time_list_content" },
                                  _vm._l(
                                    _vm.treeSelectRightList[
                                      _vm.activeIndexData
                                    ],
                                    function(item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id,
                                          class: _vm.checkedItemsData.includes(
                                            item.id
                                          )
                                            ? "time_list_item flex_style border_blue"
                                            : "time_list_item flex_style border_white",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleSelectItem(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.text))
                                          ]),
                                          _vm.checkedItemsData.includes(item.id)
                                            ? _c("img", {
                                                staticClass:
                                                  "time_list_item_icon",
                                                attrs: {
                                                  src: require("../../../../public/img/selected_icon.png"),
                                                  alt: ""
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  782882291
                )
              })
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "time_list_content" },
                  _vm._l(_vm.treeSelectRightList, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        class: _vm.checkedItemsData.includes(item.id)
                          ? "center_list_item flex_style border_blue"
                          : "center_list_item flex_style border_white",
                        on: {
                          click: function($event) {
                            return _vm.handleSelectItem(item)
                          }
                        }
                      },
                      [
                        _c("span"),
                        _c("span", { staticClass: "center_list_item_title" }, [
                          _vm._v(_vm._s(item.text))
                        ]),
                        _vm.checkedItemsData.includes(item.id)
                          ? _c("img", {
                              staticClass: "center_list_item_img",
                              attrs: {
                                src: require("../../../../public/img/selected_icon.png"),
                                alt: ""
                              }
                            })
                          : _c("span", { staticClass: "center_list_item_img" })
                      ]
                    )
                  }),
                  0
                )
              ]),
          _c(
            "div",
            { staticClass: "time_popup_button" },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    disabled: !_vm.checkedItemsData.length,
                    type: "info",
                    block: "",
                    size: "large"
                  },
                  on: { click: _vm.submitSelected }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }