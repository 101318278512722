"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'treeSelect',

  data() {
    return {
      selectedItemInfo: {},
      //选择项的所有信息
      checkedItemsData: this.initSelectedItems || [],
      activeIndexData: this.activeIndex
    };
  },

  props: {
    height: {
      type: Number,
      default: 415
    },
    multipleChoice: {
      //是否多选（默认否）
      type: Boolean,
      default: false
    },
    initSelectedItems: {
      type: [Number, Array, String],
      default: function () {
        return [];
      }
    },
    show: {
      //弹出菜单
      type: Boolean,
      default: false
    },
    treeSelectLiftList: {
      type: Array,
      //   required: true,
      default: function () {
        return [];
      }
    },
    activeIndex: {
      //分类选择左侧选项索引
      type: Number,
      default: 0
    },
    treeSelectRightList: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    }
  },
  watch: {
    initSelectedItems: {
      deep: true,

      handler(newVal) {
        this.checkedItemsData = newVal;
      }

    }
  },
  computed: {
    popupHeight() {
      if (this.height !== 415) {
        return Number(this.treeSelectRightList.length * 46 + this.height) + 'px';
      } else {
        return this.height + 'px';
      }
    }

  },

  mounted() {},

  methods: {
    handleSelectItem(item) {
      this.selectedLeftAction = this.activeIndexData;
      this.selectedItemInfo = { ...item
      };

      if (this.multipleChoice) {
        this.checkedItemsData.push(item.id);
      } else {
        this.checkedItemsData = [item.id];
      }
    },

    submitSelected() {
      this.$emit('clickPreservation', {
        show: false,
        selectedItems: this.checkedItemsData,
        selectedLeftAction: this.selectedLeftAction,
        selectedItemInfo: this.selectedItemInfo
      });
      this.activeIndexData = this.selectedLeftAction || this.activeIndexData;
    },

    cancelSelected() {
      this.activeIndexData = this.selectedLeftAction || this.activeIndex;
      this.checkedItemsData = this.initSelectedItems || [];
      this.$emit('clickPreservation', {
        show: false
      });
    }

  }
};
exports.default = _default;