"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'menuNavigation',

  data() {
    return {
      isLogin: false,
      showMenu: false,
      shipName: '商船名称',
      shipNum: localStorage.getItem("shipName") || ''
    };
  },

  computed: { // menuStatus(){
    //   return this.$store.state.declare.menuStatus
    // }
    ...(0, _vuex.mapState)({
      menuStatus: state => state.declare.menuStatus
    })
  },
  watch: {
    menuStatus(newVal) {
      this.showMenu = newVal;
    },

    showMenu(newVal) {
      this.setMenuStatus(newVal);
      this.getLogin();
    }

  },

  mounted() {
    this.getLogin();
  },

  methods: {
    getLogin() {
      this.isLogin = localStorage.getItem('token');
    },

    ...(0, _vuex.mapMutations)(['setMenuStatus']),

    //跳转
    toRouter(url) {
      this.closeMenu();

      if (this.$route.fullPath !== url) {
        this.$router.push(url);
      }
    },

    //收起菜单
    closeMenu() {
      this.showMenu = false;
    }

  }
};
exports.default = _default;