"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      pageTitle: '账户中心',
      shipName: localStorage.getItem('shipName') || ''
    };
  },

  components: {
    navBar: _navBar.default
  },
  methods: {
    editIphone() {
      this.$router.push('/editIphone');
    },

    editPass() {
      this.$router.push('/editPass');
    },

    signOut() {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('shipName');
      this.$router.push('/login');
    }

  }
};
exports.default = _default;