"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * @param {string} str
 * // 手机号验证
 */
var _default = {
  validatorIphone(val) {
    return val.trim() == '' ? true : /^1\d{10}$/.test(val);
  }

};
exports.default = _default;