"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SC_WASTE_TYPE = void 0;
const SC_WASTE_TYPE = {
  C110011: '含油污水',
  C110012: '生活污水',
  C110013: '残油(油泥)',
  C110018: '化学品洗舱水',
  C110019: '船舶垃圾'
};
exports.SC_WASTE_TYPE = SC_WASTE_TYPE;