var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "backgroundColor reg_center" },
    [
      _c("navBar", { attrs: { title: _vm.pageTitle, hasMenuImg: true } }),
      _c(
        "van-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c("van-field", {
            staticClass: "vanField",
            attrs: {
              label: "商船名称",
              required: "",
              maxlength: "25",
              rules: [
                {
                  validator: _vm.nameValidator,
                  message: "商船名称至少3位,不可超出25位"
                }
              ],
              placeholder: "请输入商船名称"
            },
            model: {
              value: _vm.form.shipName,
              callback: function($$v) {
                _vm.$set(_vm.form, "shipName", $$v)
              },
              expression: "form.shipName"
            }
          }),
          _c("van-field", {
            staticClass: "vanField",
            attrs: {
              label: "商船编号",
              required: "",
              maxlength: "25",
              rules: [
                {
                  validator: _vm.shipNoValidator,
                  message: "商船编号至少5位,不可超出25位"
                }
              ],
              placeholder: "请输入商船编号,建议使用船舶识别码"
            },
            model: {
              value: _vm.form.shipNo,
              callback: function($$v) {
                _vm.$set(_vm.form, "shipNo", $$v)
              },
              expression: "form.shipNo"
            }
          }),
          _c("van-field", {
            staticClass: "vanField",
            attrs: {
              label: "联系人姓名",
              maxlength: "15",
              rules: [
                {
                  validator: _vm.linkmanValidator,
                  message: "联系人不可超出15位"
                }
              ],
              placeholder: "请输入联系人姓名"
            },
            model: {
              value: _vm.form.linkman,
              callback: function($$v) {
                _vm.$set(_vm.form, "linkman", $$v)
              },
              expression: "form.linkman"
            }
          }),
          _c("van-field", {
            staticClass: "vanField",
            attrs: {
              type: "password",
              label: "登录密码",
              required: "",
              maxlength: "16",
              rules: [
                {
                  validator: _vm.passwordValidator,
                  message: "密码必须是8-16位英文字母、数字组合"
                }
              ],
              placeholder: "请输入登录密码"
            },
            model: {
              value: _vm.form.password,
              callback: function($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password"
            }
          }),
          _c("van-field", {
            staticClass: "vanField",
            attrs: {
              label: "所属公司",
              maxlength: "50",
              rules: [
                {
                  validator: _vm.companyValidator,
                  message: "公司名称至少3位,不可超出50位"
                }
              ],
              placeholder: "请输入船舶所属的公司名"
            },
            model: {
              value: _vm.form.company,
              callback: function($$v) {
                _vm.$set(_vm.form, "company", $$v)
              },
              expression: "form.company"
            }
          }),
          _c(
            "div",
            [
              _c("p", { staticClass: "textDesc" }, [
                _vm._v("拍摄船舶登记证书")
              ]),
              _c("van-uploader", {
                staticClass: "uploadType",
                attrs: {
                  "after-read": _vm.afterRead,
                  "max-size": 10 * 1024 * 1024,
                  "max-count": 1
                },
                on: { oversize: _vm.onOversize },
                model: {
                  value: _vm.license,
                  callback: function($$v) {
                    _vm.license = $$v
                  },
                  expression: "license"
                }
              })
            ],
            1
          ),
          _c(
            "van-button",
            { staticClass: "tipButton", attrs: { "native-type": "submit" } },
            [_vm._v("注册")]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "van-dialog",
            {
              attrs: { className: "vanDialog", showConfirmButton: false },
              model: {
                value: _vm.show,
                callback: function($$v) {
                  _vm.show = $$v
                },
                expression: "show"
              }
            },
            [
              _c("div", { staticClass: "imageCenterType" }, [
                _c("img", { attrs: { src: "/imgs/registry.png", alt: "" } }),
                _c("div", { staticClass: "successType" }, [
                  _vm._v("您已成功注册")
                ])
              ]),
              _c(
                "van-button",
                { staticClass: "ButtonIknow", on: { click: _vm.iKonw } },
                [_vm._v("我知道了")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }