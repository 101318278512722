var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-popup",
    {
      style: { height: "100%", width: "268px", background: "rgba(0,0,0,.9)" },
      attrs: { position: "left" },
      model: {
        value: _vm.showMenu,
        callback: function($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu"
      }
    },
    [
      _c("div", { staticClass: "menu_box" }, [
        _c("img", {
          staticClass: "back_icon",
          attrs: { src: "/img/return.png", alt: "" },
          on: { click: _vm.closeMenu }
        }),
        _c("ul", [
          _c(
            "li",
            {
              staticClass: "list_item",
              on: {
                click: function($event) {
                  return _vm.toRouter("/edit_declare")
                }
              }
            },
            [
              _c("img", {
                staticClass: "list_item_icon",
                attrs: { src: "/img/pollutant_declaration.png" }
              }),
              _c("span", { staticClass: "list_item_title" }, [
                _vm._v("污染物申报")
              ])
            ]
          ),
          _c(
            "li",
            {
              staticClass: "list_item",
              on: {
                click: function($event) {
                  return _vm.toRouter("/declare_list")
                }
              }
            },
            [
              _c("img", {
                staticClass: "list_item_icon",
                attrs: { src: "/img/declaration_record.png" }
              }),
              _c("span", { staticClass: "list_item_title" }, [
                _vm._v("申报记录")
              ])
            ]
          )
        ]),
        _c(
          "div",
          {
            staticClass: "login_status",
            on: {
              click: function($event) {
                return _vm.toRouter(_vm.isLogin ? "/accountCenter" : "/login")
              }
            }
          },
          [
            !_vm.isLogin
              ? _c("span", [_vm._v("登录账号")])
              : _c("div", { staticClass: "ship_box" }, [
                  _c("img", {
                    staticClass: "ship_style",
                    attrs: { src: "/img/ship_icon.png", alt: "" }
                  }),
                  _c("div", { staticStyle: { display: "inline-block" } }, [
                    _c("div", { staticStyle: { margin: "12px 0 12px 17px" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.shipName))])
                    ]),
                    _c("div", { staticStyle: { margin: "12px 0 12px 17px" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.shipNum))])
                    ])
                  ])
                ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }