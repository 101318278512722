"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseTime = parseTime;
exports.formatTime = formatTime;
exports.param2Obj = param2Obj;
exports.aliSlider = aliSlider;

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;

  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */


function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  const d = new Date(time);
  const now = Date.now();
  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * @param {string} url
 * @returns {Object}
 */


function param2Obj(url) {
  const search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
/**
 * 
 * @param {String} id 
 * @param {Function} callback 
 */


function aliSlider(id, callback) {
  // 滑块js
  var nc_token = ["FFFF0N0000000000915C", new Date().getTime(), Math.random()].join(':');
  var nc = NoCaptcha.init({
    renderTo: '#' + id,
    appkey: "FFFF0N0000000000915C",
    scene: "nc_register_h5",
    token: nc_token,
    trans: {
      "key1": "code200"
    },
    elementID: ["usernameID"],
    is_Opt: 0,
    language: "cn",
    timeout: 10000,
    retryTimes: 5,
    errorTimes: 5,
    inline: false,
    apimap: {// 'analyze': '//a.com/nocaptcha/analyze.jsonp',
      // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
    },
    bannerHidden: false,
    initHidden: false,
    // callback: function (data) {
    //     console.log(data)
    //     window.console && console.log(nc_token)
    //     window.console && console.log(data.csessionid)
    //     window.console && console.log(data.sig)
    // },
    callback: function (data) {
      data.scene = 'nc_register_h5';
      callback(data, nc_token);
    },
    error: function (s) {}
  });
  NoCaptcha.setEnabled(true);
  nc.reset(); //请务必确保这里调用一次reset()方法

  NoCaptcha.upLang('cn', {
    'LOADING': "加载中...",
    //加载
    'SLIDER_LABEL': "请向右滑动验证",
    //等待滑动
    'CHECK_Y': "验证通过",
    //通过
    'ERROR_TITLE': "非常抱歉，这出错了...",
    //拦截
    'CHECK_N': "验证未通过",
    //准备唤醒二次验证
    'OVERLAY_INFORM': "经检测你当前操作环境存在风险，请输入验证码",
    //二次验证
    'TIPS_TITLE': "验证码错误，请重新输入" //验证码输错时的提示

  });
  return nc;
}