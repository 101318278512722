"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _moment = _interopRequireDefault(require("moment"));

var _editDeclare = require("@/api/editDeclare.js");

var _enum = require("@/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "declare_detail",

  data() {
    return {
      createTimeStr: "",
      company: {},
      //船舶信息
      status: null,
      id: 1,
      pageTitle: "申报详情",
      //页面标题
      cancelShow: false,
      //撤销弹窗
      recordNo: "",
      //申报记录编号
      pollutantList: [],
      poiName: "",
      address: "",
      // 接受地点
      workTimeStr: "",
      typesOfPollutantsAllOptions: Object.keys(_enum.SC_WASTE_TYPE).map(id => ({
        id,
        text: _enum.SC_WASTE_TYPE[id],
        value: "0.00"
      })),
      longitude: "",
      //经度
      latitude: "",
      //纬度
      waitMinute: 0,
      //等待分钟数
      waitSecond: 0,
      //等待秒数
      curMinute: 0,
      //已过分钟数
      curSecond: 0,
      //已过秒数
      isfrist: true,
      refuseList: []
    };
  },

  components: {
    navBar: _navBar.default
  },

  created() {
    this.fetchApplicationsInfo();
    this.getApplicationsRefuseData();
  },

  watch: {
    tempId(newVal) {
      if (newVal) {
        this.fetchApplicationsInfo();
      }
    },

    currentMinute(val) {
      if (Number(val) >= 30) {
        clearInterval(this.timer);
        this.fetchApplicationsInfo();
      }
    }

  },

  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    next();
  },

  computed: {
    tempId() {
      return (this.$route.query || {}).id;
    },

    //当前分钟数
    currentMinute() {
      return this.toDouble(this.waitMinute + this.curMinute);
    },

    statusText() {
      let map = {
        1: "待接单",
        7: "待接单",
        2: "进行中",
        6: "进行中",
        3: "已完成",
        5: "已完成",
        8: "进行中",
        4: "已取消"
      };
      return map[this.status];
    }

  },
  methods: {
    async fetchApplicationsInfo() {
      const res = await (0, _editDeclare.getApplicationsApi)({
        id: this.tempId
      });

      if (res && res.code === 200) {
        this.formatListData(res.data);

        if (Number(this.currentMinute) < 30) {
          this.waitTime(res.data.createTime);
          this.timer = setInterval(this.handleStartTime, 1000);
        }
      }
    },

    formatListData(data) {
      this.pollutantList = Object.keys(data.wasteInfos).map(item => {
        return {
          id: item,
          value: data.wasteInfos[item],
          text: _enum.SC_WASTE_TYPE[item]
        };
      }); // console.log('data: ', data)

      this.id = data.id;
      this.status = data.status;
      this.workTime = [data.workTime];
      this.address = data.address; //详细地址

      this.poiName = data.poiName; //地标名称

      this.longitude = data.longitude; //经度

      this.latitude = data.latitude; //纬度

      this.recordNo = data.recordNo;
      this.company = {
        companyName: data.receiveCompanyName,
        mobile: data.receiveCompanyPhone
      };
      this.workTimeStr = (0, _moment.default)(data.workTime).format("YYYY-MM-DD HH:mm") + "-" + (0, _moment.default)(data.workTime + 60 * 60 * 1000).format("HH:mm");
      this.createTimeStr = (0, _moment.default)(data.createTime).format("YYYY-MM-DD HH:mm"); // console.log('this.workTimeStr: ', this.workTimeStr)
    },

    //补0
    toDouble(num) {
      if (num < 10) {
        return "0" + num;
      } else {
        return "" + num;
      }
    },

    //计时开始
    handleStartTime() {
      this.curMinute = parseInt(this.waitSecond / 60 % 60);
      this.curSecond = parseInt(this.waitSecond % 60);
      this.waitSecond++;
    },

    //等待时间
    waitTime(startTime) {
      let s1 = new Date(),
          runTime = parseInt((s1.getTime() - startTime) / 1000);
      runTime = runTime % (86400 * 365);
      runTime = runTime % (86400 * 30);
      runTime = runTime % 86400;
      runTime = runTime % 3600;
      this.waitMinute = Math.floor(runTime / 60);
      runTime = runTime % 60;
      this.waitSecond = runTime;
    },

    //复制
    handleCopy() {
      const input = document.createElement("input");
      input.setAttribute("readonly", "readonly");
      document.body.appendChild(input);
      input.setAttribute("value", this.recordNo);
      input.select();
      input.setSelectionRange(0, this.recordNo.length);

      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$toast("复制成功");
      }

      document.body.removeChild(input);
    },

    //查看地点信息
    checkMap() {
      // this.$router.push('/check_map')
      this.$router.push({
        path: "/check_map",
        query: {
          poiName: this.poiName,
          address: this.address,
          lng: this.longitude,
          lat: this.latitude
        }
      });
    },

    //确定撤销
    async handleConfirmCancel() {
      const res = await (0, _editDeclare.deleteApplicationsApi)({
        id: this.tempId
      });
      console.log("delete: ", res);

      if (res && res.code === 200) {
        this.fetchApplicationsInfo();
        this.$toast("撤销成功");
        this.cancelShow = false;
      } else {
        this.$toast(res.msg);
        this.cancelShow = false;
      }
    },

    //点击取消
    handelCancel() {
      this.cancelShow = false;
    },

    //点击撤销
    cancelDeclare() {
      this.cancelShow = true;
    },

    //修改申报
    editDeclare() {
      this.$router.push({
        path: "/edit_declare",
        query: {
          id: this.id,
          status: this.status
        }
      });
    },

    // 获取决绝理由列表
    getApplicationsRefuseData() {
      (0, _editDeclare.getApplicationsRefuseApi)({
        declareId: this.tempId,
        page: 1,
        size: 999
      }).then(res => {
        console.log("res: ", res);

        if (res.code == 200) {
          this.refuseList = res.data.content;
        } else {
          this.$toast.fail(res.msg);
        }
      }).catch(error => {
        this.$toast.fail(error);
      });
    }

  }
};
exports.default = _default;