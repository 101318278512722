"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'pollutantDeclarationList',

  data() {
    return {
      number: this.tonNum,
      show: false
    };
  },

  props: {
    pollutantList: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  watch: {
    pollutantList: {
      deep: true,

      handler(newVal) {
        this.$emit('update:pollutantList', newVal);
      }

    }
  },
  computed: {},

  mounted() {},

  methods: {
    // // 过滤输入的数字
    // formatter(value) {
    //  return value.replace(/^\D*(\d{0,5}(?:\.\d{0,2})?).*$/g, '$1')
    // },
    //五位整数或最多两位小数
    formatter(value) {
      if (/^\d{1,5}(\.(\d{1,3})?){0,1}$/g.test(value)) {
        this.oldValue = value;
        return value;
      } else {
        if (!value) {
          return '';
        }

        return this.oldValue;
      }
    },

    handleClose(index) {
      console.log(index, 'index');
      this.pollutantList.splice(index, 1);
    }

  }
};
exports.default = _default;