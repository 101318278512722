var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("navBar", { attrs: { title: _vm.pageTitle, hasbackImg: true } }),
      _c(
        "div",
        { staticClass: "editIphone" },
        [
          _vm.step === 2
            ? _c(
                "van-form",
                [
                  _c("p", { staticClass: "step2p" }, [
                    _vm._v("发送验证码到手机号: " + _vm._s(_vm.mobile) + " ")
                  ]),
                  _c("van-field", {
                    attrs: { placeholder: "输入短信验证码" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button",
                          fn: function() {
                            return [
                              _c("SendCode", {
                                ref: "scode",
                                attrs: { iphone: _vm.oldIphone, flag: "2" }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      4268116277
                    ),
                    model: {
                      value: _vm.code,
                      callback: function($$v) {
                        _vm.code = $$v
                      },
                      expression: "code"
                    }
                  }),
                  _c("div")
                ],
                1
              )
            : _vm._e(),
          _vm.step === 3
            ? _c(
                "van-form",
                [
                  _c("van-field", {
                    attrs: {
                      placeholder: "输入新手机号",
                      rules: [
                        {
                          validator: _vm.validatorIphone,
                          message: "请输入正确手机号"
                        }
                      ]
                    },
                    model: {
                      value: _vm.newIphone,
                      callback: function($$v) {
                        _vm.newIphone = $$v
                      },
                      expression: "newIphone"
                    }
                  }),
                  _c("div"),
                  _c("van-field", {
                    attrs: { placeholder: "输入短信验证码" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button",
                          fn: function() {
                            return [
                              _c("SendCode", {
                                attrs: {
                                  iphone: _vm.newIphone,
                                  flag: "3",
                                  uuid: _vm.uuid
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1405805088
                    ),
                    model: {
                      value: _vm.newCode,
                      callback: function($$v) {
                        _vm.newCode = $$v
                      },
                      expression: "newCode"
                    }
                  }),
                  _c("div")
                ],
                1
              )
            : _vm._e(),
          _vm.step === 4
            ? _c("div", [
                _c("img", {
                  staticClass: "step4img",
                  attrs: { src: "/imgs/success2x.png", alt: "" }
                }),
                _c("p", { staticClass: "step4p" }, [_vm._v("密码修改成功")])
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "van-button",
        {
          staticClass: "psf b-btn",
          attrs: { block: "", color: "#045DFF" },
          on: { click: _vm.next }
        },
        [_vm._v(_vm._s(_vm.btnText))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }