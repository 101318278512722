"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _getters = _interopRequireDefault(require("./getters"));

var _declare = _interopRequireDefault(require("./modules/declare"));

_vue.default.use(_vuex.default);

const store = new _vuex.default.Store({
  modules: {
    declare: _declare.default
  },
  getters: _getters.default
});
var _default = store;
exports.default = _default;