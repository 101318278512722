var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("navBar", { attrs: { title: _vm.pageTitle, hasbackImg: true } }),
      _c(
        "div",
        { staticClass: "editPass" },
        [
          _vm.step === 1
            ? _c(
                "van-form",
                [
                  _c("van-field", {
                    attrs: { placeholder: "输入原密码", type: "password" },
                    model: {
                      value: _vm.oldPass,
                      callback: function($$v) {
                        _vm.oldPass = $$v
                      },
                      expression: "oldPass"
                    }
                  }),
                  _c("div")
                ],
                1
              )
            : _vm._e(),
          _vm.step === 2
            ? _c(
                "van-form",
                [
                  _c("van-field", {
                    attrs: { type: "password", placeholder: "输入新密码" },
                    model: {
                      value: _vm.newPass,
                      callback: function($$v) {
                        _vm.newPass = $$v
                      },
                      expression: "newPass"
                    }
                  }),
                  _c("van-field", {
                    attrs: { type: "password", placeholder: "再次输入密码" },
                    model: {
                      value: _vm.confirmPass,
                      callback: function($$v) {
                        _vm.confirmPass = $$v
                      },
                      expression: "confirmPass"
                    }
                  }),
                  _c("div")
                ],
                1
              )
            : _vm._e(),
          _vm.step === 3
            ? _c("div", [
                _c("img", {
                  staticClass: "step4img",
                  attrs: { src: "/imgs/success2x.png", alt: "" }
                }),
                _c("p", { staticClass: "step4p" }, [_vm._v("密码修改成功")])
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "van-button",
        {
          staticClass: "psf b-btn",
          attrs: { block: "", color: "#045DFF" },
          on: { click: _vm.next }
        },
        [_vm._v(_vm._s(_vm.btnText))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }