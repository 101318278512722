"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  name: 'navBar',
  props: {
    //标题
    title: {
      type: String,
      required: false,
      default: '申报记录'
    },
    //是否显示菜单Icon
    hasMenuImg: {
      type: Boolean,
      required: false,
      default: false
    },
    //是否显示返回Icon
    hasbackImg: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {};
  },

  computed: {},

  mounted() {},

  methods: { ...(0, _vuex.mapMutations)(['setMenuStatus']),

    handlePopUpMenu() {
      this.setMenuStatus(true);
    },

    backToPreviousPage() {
      this.$router.back(-1);
    }

  }
};
exports.default = _default;