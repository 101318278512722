"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _common = require("@/api/common");

var _user = require("@/api/user");

var _default = {
  data() {
    return {
      countDowm: 60,
      isSend: false
    };
  },

  props: {
    // isSend:{
    //     default: false,
    //     type: Boolean
    // },
    // 看着这个值不是父组件传过来的 - 控制台会报错 - 看着不会影响其他的
    iphone: {
      default: '',
      type: String
    },
    flag: {
      default: '1',
      // '1' 登录验证码 '2' 修改手机号给原手机发验证码 '3' 修改手机号给新手机发验证码 '4' 找回密码发送验证码
      type: String
    },
    uuid: {
      default: '',
      type: String // flag为3时使用

    }
  },
  methods: {
    sendCode() {
      let {
        iphone,
        flag,
        uuid
      } = this;

      if (flag == '2') {
        (0, _user.accountsSms)().then(res => {
          if (res.code == 200) (0, _toast.default)('发送验证码成功');else (0, _toast.default)(res.msg);
        });
        this.openTimer();
        return;
      }

      if (iphone.trim() == '') return (0, _toast.default)('请填写手机号');
      if (!this.$tool.validatorIphone(iphone)) return (0, _toast.default)('请填写正确的手机号');
      (0, _user.loginAndRegisterCode)(iphone).then(res => res.code == 200 ? (0, _toast.default)('验证码已发送') && this.openTimer() : (0, _toast.default)(res.msg)); // if(!this.sliderRes.token) return Toast('请滑动验证码验证')
      // this.sliderRes.mobile = iphone;
      // 判断验证码是哪个功能模块的，确定请求哪个接口
      // if(flag == '1')loginSmsCode(iphone).then(res=>res.code == 200?Toast('验证码已发送') && this.openTimer():Toast(res.msg))
      // if(flag == '3')newMobileCode(iphone,{ uuid : uuid}).then(res=>res.code == 200?Toast('验证码已发送') && this.openTimer():Toast(res.msg))
    },

    // 计时器开启
    openTimer() {
      this.isSend = true;
      this.timer = setInterval(() => {
        if (this.countDowm == 0) {
          this.isSend = false;
          this.countDowm = 60;
          return clearInterval(this.timer);
        }

        this.countDowm--;
      }, 1000);
    },

    clearTimer() {
      this.isSend = false;
      this.countDowm = 60;
      clearInterval(this.timer);
    }

  }
};
exports.default = _default;