var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "top_nav" }, [
      _vm.hasMenuImg
        ? _c("img", {
            staticClass: "menu_icon",
            attrs: { src: "/img/menu_icon.png", alt: "" },
            on: { click: _vm.handlePopUpMenu }
          })
        : _vm._e(),
      _vm.hasbackImg
        ? _c("img", {
            staticClass: "back_icon",
            attrs: { src: "/img/back_off.png", alt: "" },
            on: { click: _vm.backToPreviousPage }
          })
        : _vm._e(),
      _c("p", { staticClass: "nav_title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm.hasMenuImg ? _c("p", { staticClass: "menu_icon" }) : _vm._e(),
      _vm.hasbackImg ? _c("p", { staticClass: "back_icon" }) : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }