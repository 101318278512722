"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'selcetLocation',

  data() {
    return {
      radio: '',
      map: '',
      pageTitle: '地点信息',
      center: [],
      //经度+纬度
      lat: '',
      //纬度
      lng: '',
      //经
      address: '',
      //详细地址
      poiName: '' //地址名称

    };
  },

  created() {
    let {
      lat,
      lng,
      poiName,
      address
    } = this.$route.query;
    this.lat = lat;
    this.lng = lng;
    this.poiName = poiName;
    this.address = address;
  },

  components: {
    navBar: _navBar.default
  },

  mounted() {
    this.adMap();
  },

  methods: {
    //初始化地图
    adMap() {
      var map = new BMap.Map("container");
      var point = new BMap.Point(this.lng, this.lat);
      map.centerAndZoom(point, 15); //设置中心点

      var marker = new BMap.Marker(point); // 创建标注    

      map.addOverlay(marker); // 将标注添加到地图中

      this.map = map;
      this.marker = marker;
    }

  }
};
exports.default = _default;