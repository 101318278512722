"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("vant/es/picker/style");

var _picker = _interopRequireDefault(require("vant/es/picker"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/tag/style");

var _tag = _interopRequireDefault(require("vant/es/tag"));

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/uploader/style");

var _uploader = _interopRequireDefault(require("vant/es/uploader"));

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("vant/es/overlay/style");

var _overlay = _interopRequireDefault(require("vant/es/overlay"));

require("vant/es/radio/style");

var _radio = _interopRequireDefault(require("vant/es/radio"));

require("vant/es/radio-group/style");

var _radioGroup = _interopRequireDefault(require("vant/es/radio-group"));

require("vant/es/search/style");

var _search = _interopRequireDefault(require("vant/es/search"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/loading/style");

var _loading = _interopRequireDefault(require("vant/es/loading"));

require("vant/es/tree-select/style");

var _treeSelect = _interopRequireDefault(require("vant/es/tree-select"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/number-keyboard/style");

var _numberKeyboard = _interopRequireDefault(require("vant/es/number-keyboard"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/popup/style");

var _popup = _interopRequireDefault(require("vant/es/popup"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/cell-group/style");

var _cellGroup = _interopRequireDefault(require("vant/es/cell-group"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

var _vue = _interopRequireDefault(require("vue"));

// 按需全局引入 vant组件
_vue.default.use(_button.default);

_vue.default.use(_cell.default);

_vue.default.use(_cellGroup.default);

_vue.default.use(_list.default);

_vue.default.use(_popup.default);

_vue.default.use(_field.default);

_vue.default.use(_numberKeyboard.default);

_vue.default.use(_divider.default);

_vue.default.use(_treeSelect.default);

_vue.default.use(_loading.default);

_vue.default.use(_icon.default);

_vue.default.use(_search.default);

_vue.default.use(_radioGroup.default);

_vue.default.use(_radio.default);

_vue.default.use(_overlay.default);

_vue.default.use(_toast.default);

_vue.default.use(_uploader.default);

_vue.default.use(_dialog.default);

_vue.default.use(_form.default);

_vue.default.use(_tag.default);

_vue.default.use(_empty.default);

_vue.default.use(_pullRefresh.default);

_vue.default.use(_picker.default);