var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h100p" },
    [
      _c("navBar", { attrs: { title: _vm.pageTitle, hasMenuImg: true } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLogin,
              expression: "!isLogin"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "login_main" },
            [
              _c("p", { staticClass: "login_tip" }, [
                _vm._v("登录后可查询到相应申报记录")
              ]),
              _c(
                "van-button",
                {
                  staticClass: "login_btn",
                  attrs: {
                    block: "",
                    type: "default",
                    size: "large",
                    color: "#045DFF"
                  },
                  on: { click: _vm.toLogin }
                },
                [_vm._v("登录")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLogin,
              expression: "isLogin"
            }
          ],
          staticClass: "list_main",
          attrs: { id: "list_main" }
        },
        [
          _c(
            "van-pull-refresh",
            {
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing"
              }
            },
            [
              this.listData.length
                ? _c(
                    "van-list",
                    {
                      attrs: {
                        finished: _vm.finished,
                        offset: 200,
                        "immediate-check": false
                      },
                      on: { load: this.onLoad },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "finished",
                            fn: function() {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticClass: "list_bottom",
                                    on: { click: _vm.handleHistory }
                                  },
                                  [_vm._v(_vm._s(_vm.finishedText))]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3504706643
                      ),
                      model: {
                        value: _vm.loading,
                        callback: function($$v) {
                          _vm.loading = $$v
                        },
                        expression: "loading"
                      }
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "list_ul" },
                        _vm._l(_vm.listData, function(item) {
                          return _c(
                            "li",
                            {
                              key: item.id,
                              staticClass: "list_item",
                              on: {
                                click: function($event) {
                                  return _vm.toDetail(item)
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "list_item_main" }, [
                                _c("div", [
                                  _c("p", {}, [
                                    _c("span", { staticClass: "list_title" }, [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.wasteInfos[0].text) +
                                            " "
                                        )
                                      ]),
                                      _c("span", { staticClass: "tc_red" }, [
                                        _vm._v(
                                          " " + _vm._s(item.wasteInfos[0].value)
                                        )
                                      ]),
                                      _c("span", [_vm._v(" 吨")])
                                    ]),
                                    item.wasteInfos.length > 1
                                      ? _c(
                                          "span",
                                          { staticClass: "ts_12 pl_10" },
                                          [
                                            _vm._v(
                                              "等" +
                                                _vm._s(
                                                  item.wasteInfos.length || 0
                                                ) +
                                                "种污染物"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                                  _c("p", { staticClass: "title_item" }, [
                                    _vm._v(_vm._s(item.shipName))
                                  ]),
                                  _c("p", {}, [
                                    _vm._v(_vm._s(item.workTimeStr))
                                  ])
                                ]),
                                _c("p", [_vm._v(_vm._s(item.statusTitle))])
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "btn_box",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.seeOrder(item.pdfs)
                                    }
                                  }
                                },
                                [
                                  [3, 5].includes(item.status)
                                    ? _c(
                                        "van-button",
                                        {
                                          staticClass: "order_btn",
                                          attrs: {
                                            type: "primary",
                                            size: "normal",
                                            color: "#045DFF"
                                          }
                                        },
                                        [_vm._v("查看联单")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _c("van-empty", {
                    scopedSlots: _vm._u([
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _c(
                              "p",
                              {
                                staticClass: "list_bottom",
                                on: { click: _vm.handleHistory }
                              },
                              [_vm._v(_vm._s(_vm.finishedText))]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }