import { render, staticRenderFns } from "./menuNavigation.vue?vue&type=template&id=4eca4163&scoped=true&"
import script from "./menuNavigation.vue?vue&type=script&lang=js&"
export * from "./menuNavigation.vue?vue&type=script&lang=js&"
import style0 from "./menuNavigation.vue?vue&type=style&index=0&id=4eca4163&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eca4163",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4eca4163')) {
      api.createRecord('4eca4163', component.options)
    } else {
      api.reload('4eca4163', component.options)
    }
    module.hot.accept("./menuNavigation.vue?vue&type=template&id=4eca4163&scoped=true&", function () {
      api.rerender('4eca4163', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/menuNavigation.vue"
export default component.exports