var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registry backgroundClass" },
    [
      _c("navBar", { attrs: { title: _vm.pageTitle, hasMenuImg: true } }),
      _c(
        "van-form",
        { on: { submit: _vm.sendNext } },
        [
          _c("van-field", {
            staticStyle: { "margin-top": "70px" },
            attrs: {
              type: "tel",
              label: "手机号",
              placeholder: "手机号",
              "input-align": "left"
            },
            nativeOn: {
              touchstart: function($event) {
                $event.stopPropagation()
                _vm.show = true
              }
            },
            model: {
              value: _vm.phone,
              callback: function($$v) {
                _vm.phone = $$v
              },
              expression: "phone"
            }
          }),
          _c("div"),
          _c("div", { staticStyle: { margin: "20px 0px" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  "margin-left": "-5%",
                  "margin-right": "auto",
                  width: "110%",
                  "margin-top": "20px"
                },
                attrs: { id: "__nc" }
              },
              [_c("div", { attrs: { id: "nc_register" } })]
            )
          ]),
          _c("van-field", {
            staticStyle: { "margin-top": "34px" },
            attrs: {
              clearable: "",
              placeholder: "请输入验证码",
              "input-align": "left"
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function() {
                  return [
                    !_vm.isSend
                      ? _c(
                          "van-button",
                          {
                            staticStyle: { padding: "5px 10px" },
                            attrs: { size: "mini", round: "", type: "default" },
                            on: { click: _vm.send }
                          },
                          [_vm._v(" 发送验证码 ")]
                        )
                      : _vm._e(),
                    _vm.isSend
                      ? _c(
                          "van-button",
                          {
                            staticStyle: {
                              padding: "5px 10px",
                              "align-items": "center"
                            },
                            attrs: {
                              disabled: "",
                              size: "mini",
                              round: "",
                              type: "default"
                            }
                          },
                          [_vm._v(" 重新发送(" + _vm._s(_vm.countDowm) + ")s ")]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.code,
              callback: function($$v) {
                _vm.code = $$v
              },
              expression: "code"
            }
          }),
          _c(
            "van-button",
            { staticClass: "nextButton", attrs: { "native-type": "submit" } },
            [_vm._v("下一步")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }