var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list_box" },
    [
      _vm._l(_vm.pollutantList, function(item, index) {
        return _c(
          "div",
          { key: item.id },
          [
            _c("van-field", {
              attrs: {
                "input-align": "right",
                formatter: _vm.formatter,
                placeholder: "0.000"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "list_box_item_label" }, [
                          _vm._v(_vm._s(item.text))
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "right-icon",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "delect_and_company_box" }, [
                          _c("span", { staticClass: "list_box_item_company" }, [
                            _vm._v("吨")
                          ]),
                          _c("img", {
                            staticStyle: { width: "16px" },
                            attrs: {
                              src: require("../../../../public/img/close.png")
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleClose(index)
                              }
                            }
                          })
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              ),
              model: {
                value: item.value,
                callback: function($$v) {
                  _vm.$set(item, "value", $$v)
                },
                expression: "item.value"
              }
            }),
            _c("div", { staticClass: "van-hairline--bottom" })
          ],
          1
        )
      }),
      _c("van-number-keyboard", {
        attrs: { show: _vm.show },
        on: {
          blur: function($event) {
            _vm.show = false
          }
        },
        model: {
          value: _vm.number,
          callback: function($$v) {
            _vm.number = $$v
          },
          expression: "number"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }