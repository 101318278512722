var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit_declare_box" },
    [
      !_vm.tempId
        ? _c("navBar", { attrs: { title: _vm.pageTitle, hasMenuImg: true } })
        : _c("navBar", {
            attrs: {
              title:
                _vm.tempId && _vm.detailStatus !== 4 ? "修改申请" : "重新发起",
              hasbackImg: true
            }
          }),
      !_vm.isLogin
        ? _c(
            "div",
            { staticClass: "login_main" },
            [
              _c("p", { staticClass: "login_tip" }, [
                _vm._v("登录后可进行污染物申报")
              ]),
              _c(
                "van-button",
                {
                  staticClass: "login_btn",
                  attrs: {
                    block: "",
                    type: "default",
                    size: "large",
                    color: "#045DFF"
                  },
                  on: { click: _vm.toLogin }
                },
                [_vm._v("登录 ")]
              )
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "declaration_page_style",
              staticStyle: { padding: "12px" }
            },
            [
              _c(
                "div",
                { staticClass: "pollutant_list_box" },
                [
                  _c("div", { staticClass: "pollutant_list_box_title" }, [
                    _vm._v("污染物信息")
                  ]),
                  _c("pollutantDeclarationList", {
                    attrs: { pollutantList: _vm.pollutantList }
                  }),
                  _c("div", {
                    staticStyle: {
                      width: "100%",
                      margin: "0 12px",
                      background: "rgba(210,210,210,1)"
                    }
                  }),
                  _vm.pollutantList.length <
                  _vm.typesOfPollutantsAllOptions.length
                    ? _c(
                        "div",
                        {
                          staticClass: "add_pollutant_botton_box flex_style",
                          on: { click: _vm.addpollutantInfo }
                        },
                        [
                          _c("img", {
                            staticClass: "add_img_style",
                            attrs: {
                              src: require("../../../public/img/add.png"),
                              alt: ""
                            }
                          }),
                          _c("span", { staticClass: "add_title_style" }, [
                            _vm._v("增加污染物种类")
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c("van-cell", {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("接收位置类型")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "change_and_icon_style",
                                    on: {
                                      click: function($event) {
                                        _vm.showType = true
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "6px" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.addressTypeName || "请选择"
                                          )
                                        )
                                      ]
                                    ),
                                    _c("img", {
                                      staticStyle: { width: "16px" },
                                      attrs: {
                                        src: require("../../../public/img/please_select.png"),
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("van-cell", {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("接收位置")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _vm.addressOption == 0
                                  ? _c("div", [_vm._v(" 请选择接收位置类型 ")])
                                  : _vm._e(),
                                _vm.addressOption == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "change_and_icon_style",
                                        on: {
                                          click: function($event) {
                                            _vm.showPicker = true
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-right": "6px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.addressValue.address ||
                                                  "请选择"
                                              )
                                            )
                                          ]
                                        ),
                                        _c("img", {
                                          staticStyle: { width: "16px" },
                                          attrs: {
                                            src: require("../../../public/img/please_select.png"),
                                            alt: ""
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.addressOption == 2
                                  ? _c(
                                      "div",
                                      { staticClass: "change_and_icon_style" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.otherPosition,
                                              expression: "otherPosition"
                                            }
                                          ],
                                          staticStyle: {
                                            width: "100%",
                                            border: "none",
                                            "text-align": "right"
                                          },
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.otherPosition
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.otherPosition =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("van-cell", {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("期望开始接收时间:")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "change_and_icon_style",
                                    on: { click: _vm.handleChangeTime }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.showDockingVesselTime))
                                    ])
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("van-cell", {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("收集单位")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "change_and_icon_style",
                                    on: { click: _vm.handleCompany }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "6px" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.companyValue.companyName ||
                                              "请选择"
                                          )
                                        )
                                      ]
                                    ),
                                    _c("img", {
                                      staticStyle: { width: "16px" },
                                      attrs: {
                                        src: require("../../../public/img/please_select.png"),
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.tempId && _vm.detailStatus !== 4
                ? _c("div", { staticClass: "modification_tip_title" }, [
                    _c("span", [
                      _vm._v(
                        "收集单位不可修改,若要修改,请先取消该申报单并重新发起"
                      )
                    ])
                  ])
                : _vm._e()
            ]
          ),
      _c("treeSelect", {
        attrs: {
          show: _vm.showTypePopup,
          treeSelectRightList: _vm.typesOfPollutants
        },
        on: { clickPreservation: _vm.handleSubmitType }
      }),
      _c("treeSelect", {
        attrs: {
          initSelectedItems: _vm.workTime,
          show: _vm.showTimePopup,
          treeSelectLiftList: _vm.timeSlotList,
          treeSelectRightList: _vm.timeRightList
        },
        on: { clickPreservation: _vm.handleSubmitTime }
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showCompany,
            callback: function($$v) {
              _vm.showCompany = $$v
            },
            expression: "showCompany"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              "default-index": _vm.companyIndex,
              columns: _vm.companyNameList
            },
            on: {
              cancel: function($event) {
                _vm.showCompany = false
              },
              confirm: _vm.companyConfirm
            }
          })
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showType,
            callback: function($$v) {
              _vm.showType = $$v
            },
            expression: "showType"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              "default-index": _vm.addressTypeDefaultIndex,
              columns: _vm.addressTypeColumns
            },
            on: {
              cancel: function($event) {
                _vm.showType = false
              },
              confirm: _vm.confirmAddressType
            }
          })
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPicker,
            callback: function($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              "default-index": _vm.addressDefaultIndex,
              columns: _vm.addressColumns
            },
            on: {
              cancel: function($event) {
                _vm.showPicker = false
              },
              confirm: _vm.onConfirm
            }
          })
        ],
        1
      ),
      _vm.isLogin
        ? _c(
            "div",
            {
              staticStyle: {
                padding: "0 12px",
                position: "fixed",
                width: "100%",
                bottom: "12px"
              }
            },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    disabled: !_vm.pollutantList.length,
                    type: "info",
                    block: "",
                    size: "large"
                  },
                  on: { click: _vm.handleSubmitForm }
                },
                [_vm._v("提交 ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }