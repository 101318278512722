"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _pollutantDeclarationList = _interopRequireDefault(require("./components/pollutantDeclarationList"));

var _terrSelect = _interopRequireDefault(require("./components/terrSelect"));

var _moment = _interopRequireDefault(require("moment"));

var _editDeclare = require("@/api/editDeclare.js");

var _enum = require("@/enum");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "editDeclare",

  data() {
    return {
      id: null,
      // 申报单id1
      isSelectedPosition: false,
      longitude: "",
      latitude: "",
      poiName: null,
      //地标名称（接收位置）
      // tempId: null,
      timeLeftAction: 0,
      showTypePopup: false,
      workTime: [this.connectionTime()],
      //
      shipNo: "",
      timeSlotList: [{
        text: "今天"
      }, {
        text: "明天"
      }],
      shipSlotList: [{
        text: "推荐"
      }, {
        text: "其他"
      }],
      recommendShipList: [],
      otherShipList: [],
      activeIndex: 0,
      showTimePopup: false,
      showShipPopup: false,
      showShipConflictDialog: false,
      confirmConflict: new Function(),
      pageTitle: "污染物申报",
      //页面标题
      isLogin: false,
      //是否登录状态
      selectBeginTime: this.selectBeginTime,
      pollutantList: [{
        text: "含油污水",
        value: "",
        id: "C110011"
      }],
      typesOfPollutantsAllOptions: Object.keys(_enum.SC_WASTE_TYPE).map(id => ({
        id,
        text: _enum.SC_WASTE_TYPE[id],
        value: ""
      })),
      addressValue: "",
      addressDefaultIndex: "",
      showPicker: false,
      showType: false,
      addressName: "",
      addressColumns: [],
      addressList: [],
      companyList: [],
      //收集公司数据
      companyNameList: [],
      //收集公司下拉数据
      companyIndex: "",
      //选中的索引
      showCompany: false,
      companyValue: {},
      addressType: false,
      //显示接收位置类型下拉框
      addressTypeDefaultIndex: 0,
      addressTypeName: "",
      //接收位置类型名称
      addressOldTypeName: "",
      addressTypeColumns: ["锚地", "码头", "其他海域"],
      addressOption: 0,
      //接收位置的选择类型：下拉框或填写
      otherPosition: "",
      //其他海域接收位置
      typeValue: ""
    };
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,

      handler(to, from) {
        this.isLogin = localStorage.getItem("token");

        if (this.isLogin) {
          this.showTimePopup = false;
          this.showShipPopup = false;
          this.showTypePopup = false;
          const {
            name: toName,
            query: toQuery
          } = to || {};
          const {
            name: fromName
          } = from || {};

          if (toName === "editDeclare" && fromName === "selcetLocation" || toName === "selcetLocation") {
            return;
          }

          if (toName === "editDeclare" && fromName === "input-location") {
            return;
          } // 显示旧信息
          // if (JSON.stringify(toQuery) !== '{}' && toName === 'editDeclare' && fromName !== 'selcetLocation') {
          //   if (this.tempId) {
          //     this.fetchApplicationsInfo()
          //   }
          // }


          if (fromName !== "selcetLocation" && JSON.stringify(toQuery) === "{}") {
            this.initInfo();
          }
        }
      }

    },
    addressTypeName: {
      immediate: true,

      handler(newV, oldV) {
        if (newV) {
          if (newV == "码头") {
            this.fetchWharfListData();
          } else if (newV == "锚地") {
            this.fetchAnchorageListData();
          }
        }
      }

    }
  },
  components: {
    navBar: _navBar.default,
    pollutantDeclarationList: _pollutantDeclarationList.default,
    treeSelect: _terrSelect.default
  },

  created() {
    this.$root.eventBus.$on("SUBMIT_POSITION", this.getPosition);
    console.log(/edit created/, this.isLogin, this.tempId);

    if (this.isLogin) {
      this.fetchCollectionCompanyData();

      if (this.tempId) {
        this.fetchApplicationsInfo();
      }
    }
  },

  activated() {
    this.isLogin = localStorage.getItem("token");
  },

  computed: {
    tempId() {
      return (this.$route.query || {}).id;
    },

    detailStatus() {
      return (this.$route.query || {}).status;
    },

    //时间地点是否已选择，如已选择则可以选择船舶
    canSelectShip() {
      const {
        workTime
      } = this;

      if (workTime) {
        return true;
      } else {
        return false;
      }
    },

    params() {
      let tempWasteInfos = {};
      this.pollutantList.forEach(e => {
        tempWasteInfos[e.id] = e.value;
      });
      return { ...this.addressValue,
        wasteInfos: tempWasteInfos,
        workTime: this.workTime[0],
        ...this.companyValue // shipNo: this.shipNo

      };
    },

    //选择污染物种类
    typesOfPollutants() {
      let pollutantListNum = this.pollutantList.map(e => e.id);
      return this.typesOfPollutantsAllOptions.filter(item => {
        return !pollutantListNum.includes(item.id);
      });
    },

    //接收船时间展示
    showDockingVesselTime() {
      let allTimeOption = this.timeRightList.flat();
      let tempCheckedItem = allTimeOption.filter(e => this.workTime.includes(e.id));

      if (tempCheckedItem.length) {
        return this.timeSlotList[(tempCheckedItem[0] || {}).father].text + " " + (tempCheckedItem[0] || {}).text;
      } else {
        this.workTime = [];
        return "请选择";
      }
    },

    //时间选择今明两天时间段
    timeRightList() {
      return [this.todayTimeList, this.tomorrowTimeList];
    },

    //选择接收时间今天时间段列表
    todayTimeList() {
      let hoursRemainingToday = ((0, _moment.default)().add(1, "days").startOf("day").valueOf() - this.connectionTime()) / 3600000;
      let todayArray = this.creatArray(hoursRemainingToday);
      return todayArray.map((item, index) => {
        return this.handleTimeStamp(this.connectionTime(), index, 0);
      });
    },

    //选择接收时间明天时间段列表
    tomorrowTimeList() {
      let tomorrowArray = this.creatArray(24);
      let tomorrowBeginTime = (0, _moment.default)().add(1, "days").startOf("day").valueOf();
      return tomorrowArray.map((item, index) => {
        return this.handleTimeStamp(tomorrowBeginTime, index, 1);
      });
    }

  },
  methods: {
    initInfo() {
      this.$nextTick(() => {
        this.pollutantList = [{
          text: "含油污水",
          value: "",
          id: "C110011"
        }];
        this.address = "";
        this.poiName = "";
        this.longitude = "";
        this.latitude = "";
        this.addressValue = {};
        this.workTime = [this.connectionTime()];
      });
    },

    getPosition(data) {
      this.address = data.address;
      this.poiName = data.title;
      this.longitude = data.point ? data.point.lng : "";
      this.latitude = data.point ? data.point.lat : "";
    },

    //污染物申报详情
    async fetchApplicationsInfo() {
      const res = await (0, _editDeclare.getApplicationsApi)({
        id: this.tempId
      });

      if (res && res.code === 200) {
        this.formatListData(res.data);
      }
    },

    formatListData(data) {
      this.pollutantList = Object.keys(data.wasteInfos).map(item => {
        return {
          id: item,
          value: data.wasteInfos[item],
          text: _enum.SC_WASTE_TYPE[item]
        };
      });
      this.id = data.id;
      this.workTime = [data.workTime];
      console.log(/workTime/, this.workTime); // 对于不同的接收位置类型赋值

      this.addressTypeDefaultIndex = data.receiveAddressType == 1 ? 1 : data.receiveAddressType == 2 ? 0 : 2;
      this.addressTypeName = this.addressTypeColumns[this.addressTypeDefaultIndex];

      if (data.receiveAddressType == 9) {
        this.addressOption = 2;
        this.otherPosition = data.address;
      } else {
        this.addressOption = 1;
        let addressDefaultIndex = this.addressColumns.findIndex(ele => ele === data.address);
        this.addressDefaultIndex = addressDefaultIndex === -1 ? "" : addressDefaultIndex;
        this.addressValue = {
          address: data.address,
          workAddressType: data.receiveAddressType,
          workAddressId: data.workAddressId,
          latitude: data.latitude || "",
          //纬度
          longitude: data.longitude || ""
        };
      }

      let companyIndex = this.companyNameList.findIndex(ele => ele == data.companyName);
      this.companyIndex = companyIndex == -1 ? "" : companyIndex;
      this.companyValue = {
        receiveCompanyId: data.receiveCompanyId,
        companyName: data.receiveCompanyName
      };
    },

    handleSubmitType({
      show,
      selectedItems
    }) {
      this.showTypePopup = show;

      if (selectedItems) {
        const type = selectedItems[0];
        const included = this.pollutantList.map(item => item.id).includes(type); // 解决未选择时，会添加上次选择项的bug

        if (!included) {
          let tempSelectedItemsInfo = (this.typesOfPollutantsAllOptions.filter(e => e.id === selectedItems[0]) || [])[0];
          this.pollutantList.push(tempSelectedItemsInfo);
        }
      }
    },

    handleSubmitTime({
      show,
      selectedItems,
      selectedLeftAction
    }) {
      this.showTimePopup = show;
      this.workTime = selectedItems || this.workTime;
      this.timeLeftAction = selectedLeftAction || this.timeLeftAction;
    },

    //获取当前时间的下一个整点
    connectionTime() {
      let nowTime = new Date().getTime(); //现在时间点

      let tempMin = 3600000 - nowTime % 3600000; //现在时间距离整点所差毫秒数

      return tempMin + nowTime;
    },

    handleTimeStamp(time, index, father) {
      return {
        father: father,
        id: time + 3600000 * index,
        text: (0, _moment.default)(time + 3600000 * index).format("HH:mm") + "-" + (0, _moment.default)(time + 3600000 * (index + 1)).format("HH:mm")
      };
    },

    creatArray(lengthNum) {
      return Object.keys(Array.from({
        length: lengthNum
      })).map(function (item) {
        return +item;
      });
    },

    //增加污染物种类
    addpollutantInfo() {
      this.showTypePopup = true;
    },

    //选择接收位置
    handleChangePosition() {
      this.$router.push({
        path: "/selcet_location",
        query: {
          lat: this.latitude,
          lng: this.longitude,
          address: this.poiName
        }
      });
    },

    onConfirm(value, index) {
      var {
        name,
        id,
        type
      } = this.addressList[index];
      this.addressValue = {
        address: name,
        workAddressType: type,
        workAddressId: id,
        latitude: "",
        //纬度
        longitude: ""
      }; // 码头

      if (type == 1) {
        let {
          latitude,
          longitude
        } = this.addressList[index];
        this.addressValue.latitude = latitude || "";
        this.addressValue.longitude = longitude || "";
      } else if (type == 2) {
        let latLon = this.addressList[index].longitude;

        if (latLon) {
          this.addressValue.latitude = latLon.split(",")[0];
          this.addressValue.longitude = latLon.split(",")[1];
        }
      }

      this.showPicker = false;
    },

    // 选择接收的位置类型
    confirmAddressType(value, index) {
      if (value) {
        this.addressTypeName = value;
        this.showType = false; // 更新了位置类型：接收位置的数据置空

        if (value != this.addressOldTypeName) {
          this.addressValue = "";
          this.otherPosition = "";
          this.addressOldTypeName = value;
        }

        if (value == "锚地") {
          this.addressOption = 1;
          this.fetchAnchorageListData();
        } else if (value == "码头") {
          this.addressOption = 1;
          this.fetchWharfListData();
        } else if (value == "其他海域") {
          this.addressOption = 2;
        }
      }
    },

    // 获取接收位置-锚地
    async fetchAnchorageListData() {
      const res = await (0, _editDeclare.companyAnchorageListApi)({
        type: 2
      });

      if (res && res.code === 200) {
        this.addressList = res.data;
        this.addressColumns = res.data.map(ele => ele.name);
      } else {
        this.$toast(res.msg);
      }
    },

    // 获取接收位置-码头
    async fetchWharfListData() {
      const res = await (0, _editDeclare.companyAnchorageListApi)({
        type: 1
      });

      if (res && res.code === 200) {
        this.addressList = res.data;
        this.addressColumns = res.data.map(ele => ele.name);
      } else {
        this.$toast(res.msg);
      }
    },

    // 获取收集公司
    async fetchCollectionCompanyData() {
      const res = await (0, _editDeclare.applicationCollectionCompanyApi)({
        companyType: 2,
        type: 1,
        pagination: false
      });

      if (res && res.code === 200) {
        this.companyList = res.data.content;
        this.companyNameList = this.companyList.map(item => `${item.companyName} ${item.companyPhone}`);
      } else {
        this.$toast(res.msg);
      }
    },

    //选择接收时间
    handleChangeTime() {
      let tempConnectionTime = this.connectionTime();

      if (tempConnectionTime === this.selectBeginTime) {
        this.selectBeginTime = tempConnectionTime;
      }

      this.showTimePopup = true;
    },

    //选择收集单位
    handleCompany() {
      if (!this.tempId) {
        if (this.canSelectShip) {
          this.showCompany = true;
        } else {
          this.$toast("请选择地点或者时间");
        }
      } else {
        if (this.detailStatus === 4 || this.detailStatus === 10) {
          this.showCompany = true;
        } else {
          this.$toast("收集单位不可修改,若要修改,请先取消该申报单并重新发起");
        }
      }
    },

    // 选择收集单位
    companyConfirm(value, index) {
      if (value) {
        let {
          id,
          companyName
        } = this.companyList[index];
        this.companyValue = {
          receiveCompanyId: id,
          companyName: companyName
        };
      }

      this.showCompany = false;
    },

    //点击提交
    handleSubmitForm() {
      let emptyQuantityNum = this.pollutantList.filter(e => {
        return !!e.value === false;
      });

      if (emptyQuantityNum.length) {
        this.$toast("污染物信息不能为空");
        return;
      }

      if (!this.workTime.length) {
        this.$toast("期望接收时间不能为空");
        return;
      }

      if (this.tempId && this.detailStatus !== 4) {
        this.fetchPutApplications();
      } else {
        this.submitApplications();
      }
    },

    //修改污染物申报
    async fetchPutApplications() {
      let id = this.id;

      if (this.addressOption == 2) {
        this.params.address = this.otherPosition;
        this.params.workAddressType = "9";
        this.params.workAddressId = "";
      } else {
        this.params.workAddressType = this.addressValue.workAddressType;
      } // console.log('params', this.params);


      const res = await (0, _editDeclare.putApplicationsApi)({ ...this.params,
        id
      });

      if (res && res.code === 200) {
        this.$router.replace({
          path: "/declare_detail",
          query: {
            id
          }
        }); //提交接口成功跳转到申报详情页
      } else {
        this.$toast(res.msg);
      }
    },

    //提交污染物申报
    async submitApplications() {
      this.params["shipNo"] = localStorage.getItem("shipName");

      if (this.addressOption == 2) {
        this.params.address = this.otherPosition;
        this.params.workAddressType = "9";
        this.params.workAddressId = "";
      }

      console.log("params", this.params);
      const res = await (0, _editDeclare.submitApplicationsApi)(this.params);

      if (res && res.code === 200) {
        this.$router.replace({
          path: "/declare_detail",
          query: {
            id: res.data
          }
        }); //提交接口成功跳转到申报详情页
      } else {
        this.$toast(res.msg);
      }
    },

    //跳转登录
    toLogin() {
      this.$router.push({
        path: "/login",
        query: {
          url: "/edit_declare"
        }
      });
    }

  }
};
exports.default = _default;