"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'selcetLocation',

  data() {
    return {
      radio: '',
      //勾选项
      map: '',
      //地图实例
      point: "",
      //marker : '',
      pageTitle: '选择位置',
      center: [],
      //经度+纬度
      searchKey: "",
      //搜索值
      lists: [],
      //地点列表
      searchList: [],
      //搜索结果列表     
      selectItem: '',
      //选择的地址
      loading: false,
      localSearch: null,
      //搜索实例
      showSearch: true,
      //搜索弹出层
      noSearchShow: false,
      //无搜索结果提示，无搜索结果时会显示暂无搜索结果
      address: '',
      //检索地名
      timeout: null,
      curPoint: null,
      //记录当前位置
      inputSearchDom: '',
      listDom: ''
    };
  },

  components: {
    navBar: _navBar.default
  },

  mounted() {
    this.adMap();
    this.inputSearchDom = this.$refs.search_input;
    this.listDom = this.$refs.address_container;
    window.addEventListener('touchstart', () => {
      this.inputSearchDom.blur();
    });
    this.listDom.addEventListener('touchmove', e => e.stopPropagation(), false);
  },

  created() {
    if (this.$route.query.lng) {
      this.lng = this.$route.query.lng;
      this.lat = this.$route.query.lat;
      this.address = this.$route.query.address;
    }
  },

  methods: {
    //初始化地图
    adMap() {
      this.map = new BMap.Map("container");
      this.point = new BMap.Point(this.lng, this.lat);

      if (!this.address) {
        //如果是新填报
        this.getLocation();
      } else {
        this.map.centerAndZoom(this.point, 15); //设置中心点
        //this.marker = new BMap.Marker(this.point);        // 创建标注    
        //this.map.addOverlay(this.marker);      // 将标注添加到地图中 

        this.getLocation(true);
        this.initSearch();
        this.searchNearby();
      }

      this.map.addEventListener("dragend", this.attribute);
    },

    attribute() {
      this.point = this.map.getCenter();
      this.geocoder(this.point);
    },

    //获取当前位置，并搜索附近
    getLocation(flag) {
      let _this = this;

      let geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          if (flag) {
            _this.curPoint = new BMap.Point(r.longitude, r.latitude);
          } else {
            _this.curPoint = new BMap.Point(r.longitude, r.latitude);
            _this.point = new BMap.Point(r.longitude, r.latitude);

            _this.geocoder(_this.point);
          }
        } else {
          // alert('获取定位失败')
          _this.map.centerAndZoom('台州');
        }
      });
    },

    //坐标逆解析
    geocoder(point) {
      this.point = point;
      this.map.centerAndZoom(this.point, 15); //this.marker = new BMap.Marker(this.point);
      //map.addOverlay(this.marker);

      let myGeo = new BMap.Geocoder({
        extensions_town: true
      }); // 根据坐标得到地址描述    

      myGeo.getLocation(this.point, result => {
        this.address = result.surroundingPois[0] ? result.surroundingPois[0].title : result.address;
        this.initSearch();
        this.searchNearby();
      });
    },

    // 输入内容 防抖搜索
    inputSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.timeout = setTimeout(() => {
        this.keySearch(false);
      }, 500);
    },

    //搜索
    keySearch(flag) {
      if (flag) {
        this.inputSearchDom.blur();
      }

      this.localSearch.search(this.searchKey);
      this.moveCenter = true;
    },

    //初始化地图时按照中心点搜索
    searchNearby() {
      this.localSearch.searchNearby(this.address, this.point);
      this.moveCenter = false;
    },

    //回到当前位置
    backCurAddress() {
      this.geocoder(this.curPoint);
    },

    //初始化搜索
    initSearch() {
      this.localSearch = new BMap.LocalSearch(this.map, {
        pageCapacity: 15,
        onSearchComplete: results => {
          var s = [];

          if (results && results.getCurrentNumPois && results.getCurrentNumPois() > 0) {
            for (var i = 0; i < results.getCurrentNumPois(); i++) {
              s.push(results.getPoi(i));
            }
          }

          this.searchList = s;
        }
      });
    },

    //选择地址
    selcetAddress(item, index, flag) {
      this.radio = index;
      this.selectItem = item;

      if (flag || this.moveCenter) {
        this.point = new BMap.Point(item.point.lng, item.point.lat);
        this.map.centerAndZoom(this.point, 15); //更新标记的位置  
      } //this.marker.setPosition(this.point);     

    },

    //确定
    handleConfirm() {
      this.$root.eventBus.$emit('SUBMIT_POSITION', this.selectItem);
      this.$router.back(-1);
    }

  },
  watch: {}
};
exports.default = _default;