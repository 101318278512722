"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

//
//
//
//
//
//
//
//
var _default = {
  name: 'orderPdfList',

  data() {
    let pdfs = JSON.parse(this.$route.query.pdfs);
    return {
      pdfs
    };
  },

  components: {
    navBar: _navBar.default
  }
};
exports.default = _default;