var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mymapM" },
    [
      _c("navBar", { attrs: { title: _vm.pageTitle, hasbackImg: true } }),
      _c("div", { staticClass: "con-box con-map" }, [
        _vm._m(0),
        _c("div", { staticClass: "address_info" }, [
          _c("p", { staticClass: "tw_500 ts_16" }, [
            _vm._v(_vm._s(_vm.poiName))
          ]),
          _c("p", { staticClass: "tc_666 ts_14 mt_5" }, [
            _vm._v(_vm._s(_vm.address))
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mapbox" }, [
      _c("div", { staticClass: "map", attrs: { id: "container" } }),
      _c("div", { staticClass: "sign" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }