"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _default = [{
  //污染物申报
  path: '/edit_declare',
  name: 'editDeclare',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/editDeclare/editDeclare'))),
  meta: {
    title: '污染物申报' // keepAlive: true

  }
}, {
  //选择位置
  path: '/selcet_location',
  name: 'selcetLocation',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/editDeclare/selcetLocation'))),
  meta: {
    title: '选择位置',
    keepAlive: false
  }
}, {
  //手动输入位置
  path: '/input-location',
  name: 'input-location',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/editDeclare/input-location'))),
  meta: {
    title: '手动输入位置经纬度',
    keepAlive: false
  }
}, //申报记录
{
  path: '/declare_list',
  name: 'declareList',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/declareList/declareList'))),
  meta: {
    title: '申报记录',
    keepAlive: false
  }
}, //申报详情
{
  path: '/declare_detail',
  name: 'declareDetail',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/declareList/declareDetail'))),
  meta: {
    title: '申报详情',
    keepAlive: false
  }
}, //地点信息
{
  path: '/check_map',
  name: 'checkMap',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/declareList/compontents/checkMap.vue'))),
  meta: {
    title: '地点信息'
  }
}, //联单概览
{
  path: '/order_detail',
  name: 'orderDetail',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/declareList/compontents/orderDetail.vue'))),
  meta: {
    title: '地点信息'
  }
}, //联单列表
{
  path: '/order_pdf_list',
  name: 'orderPdfList',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/declareList/compontents/orderPdfList.vue'))),
  meta: {
    title: '联单列表'
  }
}];
exports.default = _default;