var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("nav-bar", {
        attrs: { title: _vm.$route.meta.title, hasbackImg: true }
      }),
      _c("van-field", {
        attrs: {
          label: "经度",
          "label-align": "right",
          type: "number",
          "error-message": _vm.msg("point.lng")
        },
        model: {
          value: _vm.point.lng,
          callback: function($$v) {
            _vm.$set(_vm.point, "lng", _vm._n($$v))
          },
          expression: "point.lng"
        }
      }),
      _c("van-field", {
        attrs: {
          label: "纬度",
          "label-align": "right",
          type: "number",
          "error-message": _vm.msg("point.lat")
        },
        model: {
          value: _vm.point.lat,
          callback: function($$v) {
            _vm.$set(_vm.point, "lat", _vm._n($$v))
          },
          expression: "point.lat"
        }
      }),
      _c("van-field", {
        attrs: {
          label: "地标名称",
          "label-align": "right",
          "error-message": _vm.msg("title")
        },
        model: {
          value: _vm.title,
          callback: function($$v) {
            _vm.title = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "title"
        }
      }),
      _c("van-field", {
        attrs: {
          label: "详细地址",
          "label-align": "right",
          "error-message": _vm.msg("address")
        },
        model: {
          value: _vm.address,
          callback: function($$v) {
            _vm.address = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "address"
        }
      }),
      _c(
        "van-button",
        { attrs: { type: "info", block: "" }, on: { click: _vm.ok } },
        [_vm._v("确定")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }