var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "loginCon" },
      [
        _c(
          "van-form",
          { on: { submit: _vm.codeLogin } },
          [
            _c("van-field", {
              attrs: { placeholder: "商船名称" },
              model: {
                value: _vm.shipName,
                callback: function($$v) {
                  _vm.shipName = $$v
                },
                expression: "shipName"
              }
            }),
            _c("van-field", {
              attrs: {
                placeholder: "手机号",
                rules: [
                  {
                    validator: _vm.validatorIphone,
                    message: "请填写正确的手机号"
                  }
                ]
              },
              model: {
                value: _vm.iphone,
                callback: function($$v) {
                  _vm.iphone = $$v
                },
                expression: "iphone"
              }
            }),
            _c("van-field", {
              attrs: { type: "password", placeholder: "验证码" },
              scopedSlots: _vm._u([
                {
                  key: "button",
                  fn: function() {
                    return [_c("SendCode", { attrs: { iphone: _vm.iphone } })]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.code,
                callback: function($$v) {
                  _vm.code = $$v
                },
                expression: "code"
              }
            }),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "van-button",
                  {
                    attrs: {
                      block: "",
                      color: "#045DFF",
                      "native-type": "submit"
                    }
                  },
                  [_vm._v(" 申报 ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_nav", attrs: { align: "center" } }, [
      _c("p", { staticClass: "nav_title" }, [_vm._v("登录")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }