"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _user = require("@/api/user");

var _common = require("@/api/common");

var _SendCode = _interopRequireDefault(require("components/user/SendCode.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      shipName: "",
      // 商船编号
      iphone: "",
      // 手机号
      password: "",
      // 密码
      code: "",
      // 验证码
      loginMode: false // 登录方式 true 密码登录 false 验证码登录

    };
  },

  components: {
    navBar: _navBar.default,
    SendCode: _SendCode.default
  },
  computed: {},
  methods: {
    // 登录
    login(values) {
      let {
        iphone,
        password,
        code,
        loginMode
      } = this;
      if (loginMode) this.passLogin();else this.codeLogin();
    },

    // 账号密码登录
    passLogin() {
      let {
        username,
        password
      } = this;
      if (username.trim() == "") return (0, _toast.default)("请填写手机号或商船编号");
      if (password.trim() == "") return (0, _toast.default)("请填写密码");
      const params = {
        username,
        password
      };
      (0, _user.login)(params).then(res => {
        if (res.code == 200) {
          // 登录成功，将token存入localStorage 跳转到首页
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("shipName", res.data.shipName);
          localStorage.setItem("mobile", res.data.mobile);
          this.$router.push(this.$route.query.url || "/edit_declare");
        } else {
          // 用户名不存在 // 提示 <--> // 密码不正确
          (0, _toast.default)(res.msg);
        }
      }).catch(err => {
        (0, _toast.default)(err.msg);
      });
    },

    // 验证码登录
    codeLogin() {
      let {
        iphone,
        code,
        shipName
      } = this;
      if (shipName.trim() == "") return (0, _toast.default)("请填写商船名称");
      if (iphone.trim() == "") return (0, _toast.default)("请填写手机号");
      if (code.trim() == "") return (0, _toast.default)("请填写验证码");
      const params = {
        shipName: shipName,
        phone: iphone,
        smsCode: code
      };
      (0, _user.loginAndRegister)(params).then(res => {
        if (res.code === 200) {
          // 登录成功之后去那个页面
          console.log("res: ", res);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("shipName", res.data.shipName);
          localStorage.setItem("mobile", res.data.mobile);
          this.$router.push(this.$route.query.url || "/edit_declare");
        } else {
          // 提示 ->  验证码不正确
          (0, _toast.default)(res.msg);
        }
      }).catch(err => {
        (0, _toast.default)(err.msg);
      }); // console.log(iphone , password)
    },

    // 去注册
    toRegister() {
      this.$router.push("/registry");
    },

    // 修改密码
    retrievePass() {
      this.$router.push("/retrievePass");
    },

    // 滑动成功回调
    successFun() {
      console.log("滑动成功", "成功");
    },

    // 发送验证码
    sendCode() {
      let {
        iphone,
        code
      } = this;
      if (iphone.trim() == "") return (0, _toast.default)("请填写手机号");
      if (!this.validatorIphone(iphone)) return (0, _toast.default)("请填写正确的手机号");
      (0, _toast.default)("验证码已发送");
      this.isSend = true;
      let timer = setInterval(() => {
        if (this.countDowm == 0) {
          this.isSend = false;
          this.countDowm = 60;
          return clearInterval(timer);
        }

        this.countDowm--;
      }, 1000);
    },

    // 手机号验证
    validatorIphone(val) {
      return val.trim() == "" ? true : /^1\d{10}$/.test(val);
    }

  },

  mounted() {
    console.log(this.$router, this.$route);
  }

};
exports.default = _default;