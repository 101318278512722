var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("navBar", { attrs: { title: _vm.pageTitle, hasMenuImg: true } }),
      _c(
        "div",
        { staticClass: "detail_main" },
        [
          _c("div", { staticClass: "detail_top" }, [
            _c("p", { staticClass: "detail_title" }, [
              _vm._v(_vm._s(_vm.statusText))
            ])
          ]),
          _c("div", { staticClass: "detail_info" }, [
            _c("p", { staticClass: "detail_info_title" }, [_vm._v("申报信息")]),
            _c(
              "ul",
              [
                _vm._l(_vm.pollutantList, function(item) {
                  return _c("li", { key: item.id, staticClass: "waste_item" }, [
                    _c("p", [_vm._v(_vm._s(item.text))]),
                    _c("p", [
                      _c("span", { staticClass: "tc_red" }, [
                        _vm._v(_vm._s(item.value))
                      ]),
                      _c("span", {}, [_vm._v("吨")])
                    ])
                  ])
                }),
                _c("li", { staticClass: "info_item" }, [
                  _c("p", { staticClass: "flex_shink" }, [_vm._v("接收地点")]),
                  _c("div", { staticClass: "detail_address" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "margin-right": "5px"
                        }
                      },
                      [
                        _c("p", { staticClass: "tc_666 ts_12" }, [
                          _vm._v(_vm._s(_vm.address))
                        ])
                      ]
                    )
                  ])
                ]),
                _c("li", { staticClass: "info_time" }, [
                  _c("p", [_vm._v("期望开始接收时间")]),
                  _c("p", { staticClass: "tc_666" }, [
                    _vm._v(_vm._s(_vm.workTimeStr))
                  ])
                ])
              ],
              2
            )
          ]),
          _c("div", { staticClass: "detail_info" }, [
            _c("div", { staticClass: "ship_title" }, [
              _c("p", { staticClass: "ts_15" }, [_vm._v("收集单位")]),
              _c("p", [
                _c("a", { attrs: { href: "tel:" + _vm.company.mobile } }, [
                  _c("img", {
                    staticClass: "phone_img",
                    attrs: { src: "/img/phone_icon.png", alt: "" }
                  }),
                  _c("span", { staticClass: "tc_blue ts_15" }, [
                    _vm._v("联系公司")
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "ship_info" }, [
              _vm._m(0),
              _c("ul", { staticClass: "ts_15" }, [
                _c("li", [
                  _c("span", [_vm._v(_vm._s(_vm.company.companyName))])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "detail_info" }, [
            _c("p", { staticClass: "detail_info_title" }, [
              _vm._v("任务单信息")
            ]),
            _c("ul", { staticClass: "ts_15" }, [
              _c("li", { staticClass: "info_time bd_d2" }, [
                _c("p", [_vm._v("单号")]),
                _c("p", { staticClass: "tc_666" }, [
                  _vm._v(" " + _vm._s(_vm.recordNo) + " "),
                  _c(
                    "span",
                    { staticClass: "tc_blue", on: { click: _vm.handleCopy } },
                    [_vm._v("复制")]
                  )
                ])
              ]),
              _c("li", { staticClass: "info_time" }, [
                _c("p", [_vm._v("发起时间")]),
                _c("p", { staticClass: "tc_666" }, [
                  _vm._v(_vm._s(_vm.createTimeStr))
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "detail_info" }, [
            _c("p", { staticClass: "detail_info_title" }, [_vm._v("拒绝列表")]),
            _c(
              "div",
              { staticClass: "refuse" },
              _vm._l(_vm.refuseList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "detail_info_content" },
                  [
                    _c("div", { staticClass: "flxed" }, [
                      _c("div", [_vm._v("理由")]),
                      _c("div", [_vm._v(_vm._s(item.rejectReason))])
                    ]),
                    _c("div", { staticClass: "flxed" }, [
                      _c("div", [_vm._v("时间")]),
                      _c("div", [_vm._v(_vm._s(item.createTime))])
                    ])
                  ]
                )
              }),
              0
            )
          ]),
          _c(
            "div",
            { staticClass: "bottom_btn" },
            [
              [1, 7].includes(_vm.status)
                ? _c(
                    "van-button",
                    {
                      staticClass: "revoke_btn",
                      attrs: {
                        block: "",
                        type: "default",
                        size: "large",
                        color: "#7A8299"
                      },
                      on: { click: _vm.cancelDeclare }
                    },
                    [_vm._v("撤销申报")]
                  )
                : _vm._e(),
              [1, 2, 4, 6, 7, 10].includes(_vm.status)
                ? _c(
                    "van-button",
                    {
                      staticClass: "revoke_btn ml_15 mr_12",
                      attrs: {
                        block: "",
                        type: "default",
                        size: "large",
                        color: "#045DFF"
                      },
                      on: { click: _vm.editDeclare }
                    },
                    [_vm._v(_vm._s(_vm.status === 4 ? "重新发起" : "修改申报"))]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("van-overlay", { attrs: { show: _vm.cancelShow } }, [
            _c(
              "div",
              {
                staticClass: "modal_box",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c("div", { staticClass: "block" }, [
                  _c("p", { staticClass: "modal_title" }, [
                    _vm._v("确定要撤销该申报单？")
                  ]),
                  _c("div", { staticClass: "modal_bottom" }, [
                    _c(
                      "p",
                      {
                        staticClass: "modal_btn bd_ccfd9 bd_top_cccfd9",
                        on: { click: _vm.handleConfirmCancel }
                      },
                      [_vm._v(" 确定撤销 ")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "modal_btn bg_ccfd9",
                        on: { click: _vm.handelCancel }
                      },
                      [_vm._v("再想想")]
                    )
                  ])
                ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ship_img" }, [
      _c("img", { attrs: { src: require("@/assets/ship@2x.png"), alt: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }