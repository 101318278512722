"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/stable");

require("regenerator-runtime/runtime");

var _vue = _interopRequireDefault(require("vue"));

var _App = _interopRequireDefault(require("./App.vue"));

var _router = _interopRequireDefault(require("./router"));

var _store = _interopRequireDefault(require("./store"));

var _vueMeta = _interopRequireDefault(require("vue-meta"));

require("@/assets/css/index.scss");

var _config = require("@/config");

var _tool = _interopRequireDefault(require("@/utils/tool"));

require("@/plugins/vant");

require("lib-flexible/flexible.js");

require("./main.css");

require("./filters");

var _compositionApi = _interopRequireDefault(require("@vue/composition-api"));

// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
_vue.default.use(_vueMeta.default); // 引入全局样式


_vue.default.prototype.$cdn = _config.$cdn;
_vue.default.prototype.$tool = _tool.default; // 全局引入按需引入UI库 vant

_vue.default.config.productionTip = false;

_vue.default.use(_compositionApi.default);

new _vue.default({
  el: '#app',
  data: {
    eventBus: new _vue.default()
  },
  router: _router.default,
  store: _store.default,
  render: h => h(_App.default)
});