"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asyncValidator = _interopRequireDefault(require("async-validator"));

const descriptor = {
  title: [{
    required: true,
    message: '地标名称不能为空'
  }, {
    type: 'string',
    min: 1,
    max: 30,
    message: '地标名称只能写1~30字'
  }],
  address: [{
    required: true,
    message: '详细地址不能为空'
  }, {
    type: 'string',
    min: 1,
    max: 30,
    message: '详细地址只能写1~30字'
  }],
  point: {
    type: 'object',
    required: true,
    fields: {
      lng: {
        type: 'number',
        required: true,
        min: -180,
        max: 180,
        message: '经度有效范围为-180~180'
      },
      lat: {
        type: 'number',
        required: true,
        min: -90,
        max: 90,
        message: '纬度有效范围为-90~90'
      }
    }
  }
};

var _default = new _asyncValidator.default(descriptor);

exports.default = _default;