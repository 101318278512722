"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("font-awesome/css/font-awesome.min.css");

var _vueDragVerify = _interopRequireDefault(require("vue-drag-verify"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    dragVerify: _vueDragVerify.default
  },

  data() {
    return {
      handlerIcon: "fa fa-angle-double-right",
      successIcon: "fa fa-check",
      background: "#D4D4D4",
      color: "#ffffff",
      progressBarBg: "#333333",
      completedBg: "#66cc66",
      handlerBg: "#FFFFFF",
      text: "向右滑动验证",
      successText: "验证成功",
      // width: 375,
      height: 42,
      textSize: "15px",
      isCircle: 'true'
    };
  },

  props: {
    width: {
      default: 375,
      type: Number
    }
  },

  mounted() {// console.log(this.$refs.Verify.isPassing, 'this.$refs.Verify.isPassing')
    // this.resizeWidth()
  },

  computed: {
    nWidth() {
      // 根据屏幕宽度 计算滑块的宽度
      let winW = document.documentElement.offsetWidth || document.body / offsetWidth;
      return winW / 375 * this.width;
    }

  },
  methods: {
    // 滑动完成消失
    passcallback() {
      if (this.$refs.Verify.isPassing) {
        this.$emit('sliderSuccess');
      }
    } // 屏幕大小发生变化时，重新计算
    // resizeWidth(){
    //   window.onresize = function(){
    //      let winW = document.documentElement.offsetWidth || document.body/offsetWidth ;
    //      this.nWidth = winW / 375 * this.width ;
    //   }
    // }


  }
};
exports.default = _default;