var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("navBar", { attrs: { title: _vm.pageTitle, hasbackImg: true } }),
      _c(
        "div",
        { staticClass: "accountCenter" },
        [
          _c("div", { staticClass: "shipName" }, [
            _c("p", [_vm._v("商船名称")]),
            _c("p", [_vm._v(_vm._s(_vm.shipName))])
          ]),
          _c(
            "van-button",
            { staticClass: "nextButton", on: { click: _vm.signOut } },
            [_vm._v("退出登录")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }