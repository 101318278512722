"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.router = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _declare = _interopRequireDefault(require("./declare"));

_vue.default.use(_vueRouter.default);

const router = [{
  //默认跳转 污染物申报
  path: '/',
  redirect: '/login'
}, {
  //默认跳转 污染物申报
  path: '*',
  redirect: '/login'
}, //登录
{
  path: '/login',
  name: 'login',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/login/login'))),
  meta: {
    title: '登录',
    keepAlive: false
  }
}, // 注册
{
  path: '/registry',
  name: 'registry',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/registry/index'))),
  meta: {
    title: '注册新账号',
    keepAlive: false
  }
}, {
  path: '/registryCenter',
  name: 'registryCenter',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/registry/registry'))),
  meta: {
    title: '注册新账号',
    keepAlive: false
  }
}, // 找回密码 retrievePass
{
  path: '/retrievePass',
  name: 'retrievePass',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/retrievePass/retrievePass'))),
  meta: {
    title: '找回密码',
    keepAlive: false
  }
}, // 账户中心 accountCenter
{
  path: '/accountCenter',
  name: 'accountCenter',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/accountCenter/accountCenter'))),
  meta: {
    title: '账户中心',
    keepAlive: false
  }
}, // 修改密码 editPass
{
  path: '/editPass',
  name: 'editPass',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/editPass/editPass'))),
  meta: {
    title: '修改密码',
    keepAlive: false
  }
}, // 修改手机号
{
  path: '/editIphone',
  name: 'editIphone',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/editIphone/editIphone'))),
  meta: {
    title: '修改密码',
    keepAlive: false
  }
}, ..._declare.default];
exports.router = router;

const createRouter = () => new _vueRouter.default({
  // mode: 'history', // 如果你是 history模式 需要配置vue.config.js publicPath
  // base: '/app/',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: router
});

var _default = createRouter();

exports.default = _default;