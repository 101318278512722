"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applicationShipsListApi = applicationShipsListApi;
exports.companyAnchorageListApi = companyAnchorageListApi;
exports.applicationShipsOtherListApi = applicationShipsOtherListApi;
exports.applicationCollectionCompanyApi = applicationCollectionCompanyApi;
exports.submitApplicationsApi = submitApplicationsApi;
exports.getApplicationsListApi = getApplicationsListApi;
exports.getApplicationsApi = getApplicationsApi;
exports.deleteApplicationsApi = deleteApplicationsApi;
exports.putApplicationsApi = putApplicationsApi;
exports.getApplicationsRefuseApi = getApplicationsRefuseApi;

var _request = _interopRequireDefault(require("@/utils/request"));

// import qs from 'qs'
// axios
//home api
//import axios from 'axios'
//【污染物申报】推荐接收船列表
function applicationShipsListApi(params) {
  return (0, _request.default)({
    url: '/application/ships',
    method: 'get',
    params
  });
} //【污染物申报】接收位置列表 - 锚地- 码头


function companyAnchorageListApi(params) {
  return (0, _request.default)({
    url: '/company/anchorage',
    method: 'get',
    params
  });
} //【污染物申报】其他接收船列表


function applicationShipsOtherListApi(params) {
  return (0, _request.default)({
    url: '/application/ships/others',
    method: 'get',
    params
  });
} //【污染物申报】获取收集单位列表


function applicationCollectionCompanyApi(params) {
  return (0, _request.default)({
    url: '/companies/search',
    method: 'get',
    params
  });
} //【污染物申报】提交污染物申报


function submitApplicationsApi(params) {
  return (0, _request.default)({
    url: '/applications',
    method: 'post',
    data: params
  });
} //【污染物申报记录】污染物申报记录列表


function getApplicationsListApi(params) {
  return (0, _request.default)({
    url: '/applications',
    method: 'get',
    params
  });
} //【申报详情】污染物申报记录详情


function getApplicationsApi(params) {
  return (0, _request.default)({
    url: `/applications/${params.id}`,
    method: 'get',
    params
  });
} //【申报详情】撤销污染物申报


function deleteApplicationsApi(params) {
  return (0, _request.default)({
    url: `/applications/${params.id}`,
    method: 'delete',
    params
  });
} //【申报详情】污染物申报修改


function putApplicationsApi(params) {
  const {
    wasteInfos,
    address,
    poiName,
    longitude,
    latitude,
    workTime,
    workAddressType
  } = params;
  return (0, _request.default)({
    url: `/applications/${params.id}`,
    method: 'put',
    data: {
      wasteInfos,
      address,
      poiName,
      longitude,
      latitude,
      workTime,
      workAddressType
    }
  });
} // 【申报详情】拒绝列表


function getApplicationsRefuseApi(params) {
  return (0, _request.default)({
    url: `/applications/declare/reject/page`,
    method: 'get',
    params
  });
}