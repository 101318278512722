"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.smsCode = smsCode;
exports.upload = upload;

var _qs = _interopRequireDefault(require("qs"));

var _request = _interopRequireDefault(require("@/utils/request"));

// 发送验证码
function smsCode(mobile, params) {
  return (0, _request.default)({
    url: '/sms/robot/' + mobile,
    method: 'post',
    data: { ...params
    }
  });
} // 上传图片


function upload(formdata) {
  return (0, _request.default)({
    url: '/upload',
    method: 'post',
    data: formdata,
    headers: {
      ['Content-type']: 'multipart/form-data'
    }
  });
}