"use strict";

var _interopRequireDefault = require("/root/workspace/merchantman_declaration-web_Tp9U/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _navBar = _interopRequireDefault(require("components/navBar.vue"));

var _compositionApi = require("@vue/composition-api");

var _validators = _interopRequireDefault(require("./validators"));

var _asyncValidatorMessageGetter = _interopRequireDefault(require("@/utils/async-validator-message-getter"));

//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    navBar: _navBar.default
  },
  name: 'input-location',

  setup(props, {
    root
  }) {
    const title = (0, _compositionApi.ref)('');
    const address = (0, _compositionApi.ref)('');
    const point = (0, _compositionApi.ref)({
      lat: '',
      lng: ''
    });
    const errors = (0, _compositionApi.ref)({});
    const fields = (0, _compositionApi.ref)({});
    const msg = (0, _asyncValidatorMessageGetter.default)(fields);

    function ok() {
      // TODO 需要处理BD09坐标转换
      const data = {
        title: title.value,
        address: address.value,
        point: point.value
      };

      _validators.default.validate(data).then(() => {
        root.eventBus.$emit('SUBMIT_POSITION', data);
        root.$router.back(-1);
      }).catch(error => {
        errors.value = error.errors;
        fields.value = error.fields;
      });
    }

    return {
      title,
      address,
      point,
      fields,
      msg,
      ok
    };
  }

};
exports.default = _default;